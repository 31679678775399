import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import { TwitterTweetEmbed } from 'react-twitter-embed';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Masonry from '@mui/lab/Masonry';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import profileHeader from '../assets/profile-header.png';
import profileDecoration from '../assets/profile-decoration.png';
import memesBanner from '../assets/memes-banner.png';
import firstMemesBanner from '../assets/first-memes-banner.png';
import { NavLink } from "react-router-dom";
import MemeImage from '../components/MemeImage';
import levelBadge from '../assets/level-badge.png';
import levelBlob from '../assets/level-blob.png';
import babyCoc from '../assets/baby-coc.png';
import normieCoc from '../assets/normie-coc.png';
import midCoc from '../assets/mid-coc.png';
import chadCoc from '../assets/chad-coc.png';
import monsterCoc from '../assets/monster-coc.png';
import chickenShit from '../assets/chicken-shit.png';
import closeButton from '../assets/close-button.png';

import { useWallet, useConnection, useAnchorWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton, useWalletModal } from '@solana/wallet-adapter-react-ui';
import { SigninMessage } from "../utils/signMessage";
import base58 from "bs58";
import nacl from 'tweetnacl';
import Button from '@mui/material/Button';
import cocTwitter from '../assets/coc-twitter.png';
import phantomCoc from '../assets/phantom-coc.png';
import heartCoc from '../assets/heart-coc.png';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { Modal } from '@mui/material';

const ConnectButton = styled(Button)({
    backgroundColor: '#FFF',
    borderRadius: 20,
    color: '#000',
    border: '2px solid #000',
    boxShadow: '2px 4px #000',
    '&:hover': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
      '&:disabled': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
});

const modalStyle2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 200,
    bgcolor: '#009251',
    borderRadius: 4,
    border: '2px solid #000000',
    boxShadow: '8px 8px #000000',
  };

  const StyledButton = styled(Button)({
    '&:hover': {
        transitionProperty: 'scale',
        transitionDuration: '0.5s',
        scale: '1.2',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
});

const messageToSign = 'Sign this to verify your wallet address for the $COC claim.';

function Claim(props) {
    let { username } = useParams();
    const [items, setItems] = useState([]);
    const [profile, setProfile] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [doneLoading, setDoneLoading] = useState(false);
    const [signature, setSignature] = useState();
    const [linkedWallet, setLinkedWallet] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [submitOpen, setSubmitOpen] = useState(false);
    const handleSubmitClose = () => setSubmitOpen(false);

    const [connectOpen, setConnectOpen] = useState(false);
    const handleConnectClose = () => setConnectOpen(false);

    const [authenticated, setAuthenticated] = useState(false);
    const location = useLocation();

    const { wallet, publicKey, connected, signMessage } = useWallet();
    const anchorWallet = useAnchorWallet();

    const handleTwitterConnect = () => {
        const loginUrl = `${process.env.REACT_APP_MEME_API_URL}/auth/twitter?redirect=${location.pathname}`;
        window.open(loginUrl, "_self");
    }

    async function sign() {
        try {
          const message = new TextEncoder().encode(messageToSign);
          const uint8arraySignature = await signMessage(message);
    
            let jwt = Cookies.get('token');

            if (jwt) {
                const url = `${process.env.REACT_APP_MEME_API_URL}/claim/wallet`;
                const options = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` },
                    body: JSON.stringify({
                        signature: base58.encode(uint8arraySignature),
                        wallet: anchorWallet.publicKey.toString()
                    }),
                };
                const resp = await fetch(url, options);
                const respJson = await resp.json();
                if (respJson && respJson.wallet && respJson.wallet.length > 0) {
                    console.log(respJson.wallet);
                    setHasError(false);

                    setLinkedWallet(respJson.wallet);
                    setConnectOpen(true);
                } else {
                    setHasError(true);
                    setConnectOpen(true);
                }
               

            }

        //   setSignature(base58.encode(uint8arraySignature));
        } catch (e) {
            setHasError(true);
            setConnectOpen(true);

          console.log('could not sign message');
        }
      }

    //   useEffect(() => {
    //     console.log(signature)
    //     if (signature && signature.length > 0) {
    //         verify();
    //     }
    // }, [signature]);

    useEffect(() => {
        console.log(props.authenticated);

        setAuthenticated(props.authenticated);
    }, [props]);

    useEffect(() => {
        
        if (connected) {
            console.log(`connected`, publicKey.toBase58());
        }
    }, [wallet, connected]);

      
    return (
        <div className="profile-div">
            <Container align="center" className="coc-profile-vector">

            <Box position="absolute" className='claim-heart-coc' >
                <img src={heartCoc} />
            </Box>

                <Box display="flex" justifyContent="center" mt={{ xs: 14, lg: 12 }}>
                    <Typography className='header3'>CLAIM YOUR $COC</Typography>
                </Box>
                <Box mt={6}>
                    <Typography component={'h4'} fontSize={32} mb={2}>1. Connect your Twitter</Typography>

                    <ConnectButton variant="contained" size="large" disableElevation disabled={authenticated} onClick={handleTwitterConnect}>
                        <Typography component="span" pr={2}>{authenticated ? 'CONNECTED' : 'CONNECT TWITTER'}</Typography>
                        <img src={cocTwitter} width="31" />
                    </ConnectButton>
                </Box>

                <Box mt={10}>
                    <Typography component={'h4'} fontSize={32} mb={2}>2. Connect your wallet</Typography>
                    <WalletMultiButton />
                </Box>

                <Box mt={10}>
                    <Typography component={'h4'} fontSize={32} mb={2}>3. Sign message to link your wallet</Typography>

                    <ConnectButton variant="contained" size="large" disableElevation  disabled={!publicKey || !authenticated} onClick={sign}>
                        <Typography component="span" pr={2}>LINK WALLET</Typography>
                        <img src={phantomCoc} width="31" />
                    </ConnectButton>
                </Box>

                <Box mt={8} mb={12}>
                <Typography component={'h4'} fontSize={38} sx={{ transform: 'rotate(-2deg)'}}>$COC will be available to claim after the competition ends</Typography>

                </Box>
            </Container>

            <Modal open={connectOpen} onClose={handleConnectClose}>
            <Box> 
                <Stack className='modal-connect' sx={{ ...modalStyle2 }} justifyContent="center" alignItems="center">
                    {/* <img src={checkMark} /> */}
                    {!hasError && <Box sx={{ textAlign: 'center' }} mb={2}>
                        <Typography component={'h4'} fontSize={36}>You have linked your wallet! <span style={{ color: '#FFD400' }}></span></Typography>
                    </Box>}
                    
                    {hasError && <Box sx={{ textAlign: 'center' }} mb={2}>
                        <Typography component={'h4'} fontSize={36}>Could not link your wallet! <span style={{ color: '#FFD400' }}></span></Typography>
                    </Box>}

                    <Box sx={{ display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap' }}>
                        <ConnectButton onClick={handleConnectClose} variant="contained" size="large" disableElevation>
                            <Typography component="span">{'CLOSE ME'}</Typography>
                            {/* <img src={cocTwitter} width="31" /> */}
                        </ConnectButton>
                    </Box>
                </Stack>

                {/* <StyledButton variant="text" sx={{ position: 'absolute', right: 60, top: 40 }}>
                    <img src={closeButton} onClick={handleSubmitClose} width="68" />
                </StyledButton> */}
                </Box>
            </Modal>
            
        </div>
    )
}

export default Claim;