import React, { useState, useEffect,useRef } from 'react';
import { useParams } from "react-router";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import lockIcon from '../assets/lock-icon.png';
import lockIconWhite from '../assets/lock-icon-white.png';
import eyeLoader from "../assets/eye-loader.png";
import '../assets/styles/reveal.css';
import 'swiper/css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Reveal() {
    let { revealNum } = useParams();
    const [reveals, setReveals] = useState([]);
    const [choice, setChoice] = useState(null);
    const [cetLoaded, setCetLoaded] = useState(false);
    const [klem1Loaded, setKlem1Loaded] = useState(false);
    const [klem2Loaded, setKlem2Loaded] = useState(false);
    const [revealLoaded, setRevealLoaded] = useState(false);
    const [slideIndex,setSlideIndex]= useState(0);
    const [updateCount,setUpdateCount]= useState(0);
    const [revealChoices, setRevealChoices] = useState([]);
    const [numReveals, setNumReveals] = useState(0);

    const customSlider = useRef()
    useEffect(() => {
        async function getRevealUrls() {
            const url = `${process.env.REACT_APP_MEME_API_URL}/reveal-urls`;
            const resp = await fetch(url);
            const urls = await resp.json();
            // const urls = [{"cet":"https://cets-reveal.s3.us-west-2.amazonaws.com/5285-cet.png","klem1video":"https://cets-reveal.s3.us-west-2.amazonaws.com/reveal-klem1.mp4","klem2video":"https://cets-reveal.s3.us-west-2.amazonaws.com/reveal-klem2.mp4"},{"cet":"https://cets-reveal.s3.us-west-2.amazonaws.com/4275-cet.png","klem1video":"https://cets-reveal.s3.us-west-2.amazonaws.com/4275-klem1.mp4","klem2video":"https://cets-reveal.s3.us-west-2.amazonaws.com/4275-klem2.mp4"},{},{},{}]
            // console.log(`reveal urls`, urls);

            setReveals(urls);
          

            if (Object.keys(urls[0]).length > 0) {
                if (revealNum && urls[parseInt(revealNum) -1].cet) {
                    setChoice(parseInt(revealNum));
                } else {
                    setChoice(1);
                }
            }

            // document.documentElement.addEventListener('mouseleave', handleWindowLeave);
        }

        getRevealUrls();
    }, []);

    useEffect(() => {
        let num = 0;
        for (let i = 0; i < reveals.length; i++) {
            if (Object.keys(reveals[i]).length > 0 && reveals[i].cet) {
                num++;
            }
        }

        setNumReveals(num);
    }, [reveals]);
    
    useEffect(() => {
        if (klem1Loaded && klem2Loaded && cetLoaded) {
            setRevealLoaded(true);
        }
    }, [klem1Loaded]);

    useEffect(() => {
        if (klem1Loaded && klem2Loaded && cetLoaded) {
            setRevealLoaded(true);
        }
    }, [klem2Loaded]);

    useEffect(() => {
        if (klem1Loaded && klem2Loaded && cetLoaded) {
            setRevealLoaded(true);
        }
    }, [cetLoaded]);

    useEffect(() => {
       if (revealLoaded) {
            const topNavBar = document.getElementById('topNav');
            topNavBar.classList.remove('white-nav-bar');
       }
    }, [revealLoaded]);

    useEffect(() => {
        if (choice) {
            setKlem1Loaded(false);
            setKlem2Loaded(false);
            setCetLoaded(false);
            setRevealLoaded(false);

            const second = document.querySelector('.middle');
        
            // load videos
            const leftVideo = document.getElementById('leftVideo');
            leftVideo.src = reveals[choice-1].klem1video;
            leftVideo.load();
            leftVideo.addEventListener('loadeddata', function() {
                setKlem1Loaded(true);
             }, false);

            const rightVideo = document.getElementById('rightVideo');
            rightVideo.src = reveals[choice-1].klem2video;
            rightVideo.load();
            rightVideo.addEventListener('loadeddata', function() {
                setKlem2Loaded(true);
             }, false);
             
            // overlays
            const leftVideoContainer = document.getElementById('leftVideoContainer');
            leftVideoContainer.classList.add('overlay');

            const rightVideoContainer = document.getElementById('rightVideoContainer');
            rightVideoContainer.classList.add('overlay');

            const secondImage = new Image();
            secondImage.src = reveals[choice-1].cet;
            secondImage.classList.add('smooth-image');
            secondImage.classList.add('image-hidden');
            
            secondImage.onload = function() {
                second.style.backgroundImage = `url(${secondImage.src})`;
                setTimeout( () => {
                    setCetLoaded(true);
                    secondImage.classList.remove('image-hidden');
                    secondImage.classList.add('image-visible');
                }, 500)
            };

            const first = document.querySelector('.left');
            const third = document.querySelector('.right');
    
            first.style.width = "50%";
            second.style.width = "50%";
            third.style.width = "50%";
            second.style.left = "50%";
            second.style['background-position-x'] = '-250px';

            first.style.left = "0%";

            second.style['background-position-x'] = '0';
            second.style.left = "25%";

            third.style.left = "75%";


            const topNavBar = document.getElementById('topNav');
            topNavBar.classList.add('white-nav-bar');

            const container = document.getElementsByClassName('mobile-reveal-container')[0];
            if (container) {
                container.style.backgroundColor = `#` + reveals[choice-1].cetBgColor;
            }
        }
    }, [choice]);

    const setFirstBg = () => {
        const container = document.getElementsByClassName('mobile-reveal-container')[0];
        if (container) {
            container.style.backgroundColor = `rgb(` + reveals[choice-1].klem1bgColor + `)`;
        }
    }

    useEffect(() => {
        const container = document.getElementsByClassName('mobile-reveal-container')[0];
        if (container) {
            if (slideIndex === 0) {
                container.style.backgroundColor = `rgb(` + reveals[choice-1].klem1bgColor + `)`;
            } else if (slideIndex === 2) {
                container.style.backgroundColor = `rgb(` + reveals[choice-1].klem2bgColor + `)`;
            } else {
                container.style.backgroundColor = `#` + reveals[choice-1].cetBgColor;
            }
        }
    }, [slideIndex]);

    const updateBg = () => {
        const container = document.getElementsByClassName('mobile-reveal-container')[0];

        if (container) {
            if (slideIndex === 0) {
                container.style.backgroundColor = `rgb(` + reveals[choice-1].klem1bgColor + `)`;
            } else if (slideIndex === 2) {
                container.style.backgroundColor = `rgb(` + reveals[choice-1].klem2bgColor + `)`;
            } else {
                container.style.backgroundColor = `#` + reveals[choice-1].cetBgColor;
            }
        }
    }

    const changeSlide = (index) => {
        const firstSlide = document.getElementById('firstSlide');
        const secondSlide = document.getElementById('secondSlide');
        const thirdSlide = document.getElementById('thirdSlide');

        if (index === 0) {
            firstSlide.style.width = "80%";
            firstSlide.style.marginLeft = "-3%";

            const videoElem = firstSlide.firstChild;
            setTimeout(() => {
                videoElem.play();
            }, 500);
        }

        if (index === 1) {
            firstSlide.style.width = "80%";
            firstSlide.style.marginLeft = "0";
            secondSlide.style.width = "80%";

            const videoElem1 = firstSlide.firstChild;
            if (videoElem1) {
                videoElem1.pause();
            }

            const videoElem2 = thirdSlide.firstChild;
            if (videoElem2) {
                videoElem2.pause();
            }
        }

        if (index === 2) {
            secondSlide.style.width = "100%";
            thirdSlide.style.width = "75%";
            
            const videoElem = thirdSlide.firstChild;
            setTimeout(() => {
                videoElem.play();
            }, 500);
        }

        // console.log('we here', index);
    }

    const playVideo = (container, videoElemId) => {
        const videoElem = document.getElementById(videoElemId);
        videoElem.style.objectPosition = '50% 50%';

        videoElem.play();

        const videoContainer = container.firstChild.parentNode.parentNode;
        videoContainer.style.width = container.offsetWidth;
        videoContainer.style.height = container.offsetHeight;       
    };

    const stopVideo = (videoElemId, videoContainerId) => {
        const videoElem = document.getElementById(videoElemId);
        const videoContainerElem = document.getElementById(videoContainerId);

        // console.log(videoElem.style.objectPosition);
        // videoContainerElem.style.display = "none";
        videoElem.style.objectPosition = '-250px 50%';

        videoElem.pause();
    };

    const handleFirstHover = (e) => {
        if (revealLoaded) {
        const first = document.querySelector('.left');
        const second = document.querySelector('.middle');
        const third = document.querySelector('.right');

        stopVideo('rightVideo', 'rightVideoContainer');
        
        first.style.width = "50%";
        second.style.width = "50%";
        third.style.width = "50%";
        second.style.left = "50%";
        second.style['background-position-x'] = '-250px';

        const leftVideoContainer = document.getElementById('leftVideoContainer');
        leftVideoContainer.classList.remove('overlay');

        playVideo(e.target, 'leftVideo');
    
        second.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${reveals[choice-1].cet})`;
        }
    }
    
    const handleSecondHover = (e) => {
        if (revealLoaded) {

        const first = document.querySelector('.left');
        const second = e.target;
        const third = document.querySelector('.right');

        stopVideo('leftVideo', 'leftVideoContainer');
        stopVideo('rightVideo', 'rightVideoContainer');

        const leftVideoContainer = document.getElementById('leftVideoContainer');
        leftVideoContainer.classList.add('overlay');

        const rightVideoContainer = document.getElementById('rightVideoContainer');
        rightVideoContainer.classList.add('overlay');
        
        first.style.left = "0%";

        second.style['background-position-x'] = '0';
        second.style.left = "25%";

        third.style.left = "75%";

        second.style.backgroundImage = `url(${reveals[choice-1].cet})`;
        }
    }

    const handleThirdHover = (e) => {
        if (revealLoaded) {

            const vWidth = window.innerWidth;
            // console.log('width', vWidth);
            
            const second = document.querySelector('.middle');
            const third = document.querySelector('.right');
            stopVideo('leftVideo', 'leftVideoContainer');

            const rightVideoContainer = document.getElementById('rightVideoContainer');
            rightVideoContainer.classList.remove('overlay');

            second.style['background-position-x'] = '-250px';
            second.style.left = "25%";

            third.style.left = "50%";

            playVideo(e.target, 'rightVideo');

            second.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${reveals[choice-1].cet})`;
        }
    }

    const ChoiceButton = styled(Button)({
        backgroundColor: '#FFFFFF',
        color: '#101010',
        maxWidth: 35,
        minWidth: 35,
        maxHeight: 35,
        minHeight: 35,
        fontSize: 20,
        borderRadius: 10,
        ':hover': {
            backgroundColor: '#FFFFFF',
        },
    });
    
    const ActiveChoiceButton = styled(Button)({
        backgroundColor: '#101010',
        color: '#FFFFFF',
        maxWidth: 35,
        minWidth: 35,
        maxHeight: 35,
        minHeight: 35,
        fontSize: 20,
        borderRadius: 10,
        border: '2px solid #FFFFFF',

        ':hover': {
            backgroundColor: '#101010',
        },
    });
    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        centerPadding: "60px",
        slidesToShow: 1,
        variableWidth: true,
        adaptiveHeight: true,
        arrows:false,
        speed: 500,
        initialSlide: 1,
        afterChange: () =>{
            setUpdateCount(updateCount+1)
        },
        beforeChange: (current, next)=>{
            if (next === 0 && current === 1) {
                setFirstBg();
            }
           setSlideIndex(next)
        },
      };
    const chooseNextReveal = () => {
        let numChoices = 0;

        for (let i = 0; i < 5; i++) {
            if (reveals[i] && reveals[i].klem1video) {
                numChoices += 1;
            }
        }

        customSlider.current.slickGoTo(1);
        if (choice === numChoices) {
            setChoice(1);
        } else {
            setChoice(choice + 1);
        }
        updateBg();
    }

    const chooseReveal = (choice) => {
        setChoice(choice);
    }

    const choices = [];
    
    for (let i = 0; i < 5; i++) {
        if (choice === (i+1) && reveals[i] && reveals[i].klem1video) {
            choices.push(<ActiveChoiceButton variant="contained">{i+1}</ActiveChoiceButton>);
        } else if (choice !== (i+1) && reveals[i] && reveals[i].klem1video) {
            choices.push(<ChoiceButton variant="contained" onClick={() => chooseReveal(i+1)}>{i+1}</ChoiceButton>);
        } else {
            choices.push(<ChoiceButton variant="contained"><img width="16" src={lockIcon} /></ChoiceButton>);
        }
    }
 
    if (reveals[0] && Object.keys(reveals[0]).length > 0) {
        return (
            <div>
            <div className="reveal-container">
                    <div className="left" onMouseEnter={(e) => handleFirstHover(e)}>
                        <div id="leftVideoContainer">
                            <video muted loop id="leftVideo">
                                <source src="#" type="video/mp4" />
                            </video>
                        </div>
                    </div> 
                <div className="middle" onMouseEnter={(e) => handleSecondHover(e)}>
                    <div id="middleImageContainer">
                        <img id="middleImage" />
                    </div>
                </div>
                <div className="right" onMouseEnter={(e) => handleThirdHover(e)}>
                    <div id="rightVideoContainer">
                        <video muted loop id="rightVideo">
                            <source src="#" type="video/mp4" />
                        </video>
                    </div>
                </div>
                
                <Stack direction="row" spacing={2} className="reveal-menu">
                    {choices}
                </Stack>

                {!revealLoaded && <div className="loading-indicator">
                        <img src={eyeLoader} className='loading-animation'/>
                </div>}
            </div>

            <div class={`mobile-reveal-container reveal-${slideIndex+1}`}>
            <Slider ref={slider => (customSlider.current = slider)}  {...settings} className="mySwiper">
                <div className='slider-container'>
                    <div className='slider-item'>
                        <video className='slider-video' src={reveals[choice-1].klem1video} muted loop autoPlay={true} style={{ objectFit: 'cover' }} />
                    </div>
                </div>
                <div className='slider-container'>
                    <div className='slider-item'>
                        <div className='slider-item-image' style={{backgroundImage:`url(${reveals[choice-1].cet})`}} ></div>
                    </div>
                </div>
                <div className='slider-container'>
                    <div className='slider-item'>
                        <video className='slider-video' src={reveals[choice-1].klem2video} muted loop autoPlay={true} style={{ objectFit: 'cover' }} />
                    </div>
                </div>
          </Slider>
          
          {numReveals > 1 && <div className="button-next-div">
            <button className="button-next" onClick={() => chooseNextReveal()}>
                NEXT CET
            </button>
          </div>}

          {!revealLoaded && <div className="loading-indicator">
                        <img src={eyeLoader} className='loading-animation'/>
            </div>}
    </div>
        </div>
        )
    } else if (reveals[0] && Object.keys(reveals[0]).length === 0) {
        return (
            <div>
            <div className="no-reveal-container">
            
            <div className="left-no-reveal">
                <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <h1 style={{ color: '#FFFFFF', margin: 0 }}>?</h1>
                </Stack>
            </div>

            <div className="middle-no-reveal">
                <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <h1 style={{ color: '#FFFFFF', margin: 0 }}>?</h1>
                </Stack>
            </div>

            <div className="right-no-reveal">
                <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <h1 style={{ color: '#FFFFFF', margin: 0 }}>?</h1>
                </Stack>
            </div>

            <Stack direction="row" spacing={2} className="reveal-menu">
                {choices}
            </Stack>
        </div>

        <div class={`mobile-no-reveal-container`}>
            <Slider ref={slider => (customSlider.current = slider)}  {...settings} className="mySwiper">
                <div className='slider-container'>
                    <div className='slider-item-empty slider-item'>
                    <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }} direction="row">
                        <h1 className="no-reveal-text">?</h1>
                        </Stack>
                    </div>
                </div>
                <div className='slider-container'>
                    <div className='slider-item-empty slider-item'>
                        <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }} direction="row">
                            <h1 className="no-reveal-text">?</h1>
                        </Stack>
                    </div>
                </div>
                <div className='slider-container'>
                    <div className='slider-item-empty slider-item'>
                        <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }} direction="row">
                         <h1 className="no-reveal-text">?</h1>
                        </Stack>
                    </div>
                </div>
          </Slider>

          <div className="button-next-div">
          <button className="button-locked">
            <img src={lockIconWhite} />
            <span>LOCKED</span>
          </button>
          </div>
        </div>
        </div>
        )
    }
}

export default Reveal;