import React, { useEffect, useState, useMemo } from 'react';
import { PublicKey, Transaction, clusterApiUrl } from '@solana/web3.js';
import {
    Program, AnchorProvider, web3, utils,
  } from '@project-serum/anchor';
import idl from '../assets/idl/onchain_voting.json';
  
import { useWallet, useConnection, useAnchorWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
// import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { Token, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import loser from '../assets/loser.png';
import selectAuction from '../assets/select-auction.png';
import mobileSelectAuction from '../assets/mobile-select-auction.png';
import dontForget from '../assets/dont-forget.png';
import wtfHappening from '../assets/wtf-happening.png';
import booger1 from '../assets/auction-booger1.png';
import booger1Mobile from '../assets/booger-mobile.png';
import booger2 from '../assets/auction-booger2.png';
import xIcon from '../assets/x-icon.png';
import xIconLarge from '../assets/x-icon-large.png';
import blueCheck from '../assets/blue-check.png';
import blueCheckLarge from '../assets/blue-check-large.png';

import character1 from '../assets/character1.png';
import character2 from '../assets/character2.png';
import character3 from '../assets/character3.png';
import character4 from '../assets/character4.png';
import character5 from '../assets/character5.png';
import character6 from '../assets/character6.png';
import character7 from '../assets/character7.png';
import character8 from '../assets/character8.png';
import character9 from '../assets/character9.png';
import character10 from '../assets/character10.png';
import character11 from '../assets/character11.png';
import character12 from '../assets/character12.png';
import howItWorks from '../assets/how-it-works.png';
import cocEyes from '../assets/coc-eyes.png';
import cocToes1 from '../assets/coc-toes1.png';
import ohCoc from '../assets/oh-coc.png';
import trollCoc from '../assets/troll-coc.png';
import bootyCoc from '../assets/booty-coc.png';
import stareCoc from '../assets/stare-coc.png';
import creamCoc from '../assets/cream-coc.png';

import closeButton from '../assets/close-button.png';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

const programId = new PublicKey(idl.metadata.address);
const opts = {
    preflightCommitment: "processed"
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', md: '50%' },
    bgcolor: '#009251',
    borderRadius: 4,
    paddingX: '60px',
    paddingY: '40px',
    border: '2px solid #000000',
    boxShadow: '4px 4px #000',
  };

const VoteButton = styled(Button)({
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 40,
    fontSize: 28,
    border: '3px solid #000000',
    paddingLeft: '1em',
    paddingRight: '1em',
    '&:hover': {
        backgroundColor: '#B5C6FF',
      },
});

const SubmitVoteButton = styled(Button)({
    backgroundColor: '#000000',
    color: '#ffffff',
    borderRadius: 40,
    fontSize: '14px',
    fontFamily: 'Space Grotesk',
    fontWeight: '700',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#B5C6FF',
      },
});

const StyledButton = styled(Button)({
    '&:hover': {
        opacity: 0.7,
        transitionProperty: 'opacity',
        transitionTiming: '0.5s',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
});

const CloseButton = styled(Button)({
    backgroundColor: '#FFF',
    borderRadius: 20,
    color: '#000',
    border: '2px solid #000',
    boxShadow: '2px 4px #000',
    '&:hover': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
      '&:disabled': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
});


const network = "http://127.0.0.1:8899";

function isTouchEnabled() {
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );
}

function Vote(props) {
    let connection;

    // if (!process.env.REACT_APP_SOLANA_NETWORK) {
    //     connection = new web3.Connection(network, "confirmed");
    // } else {
    //     connection = new web3.Connection(clusterApiUrl(process.env.REACT_APP_SOLANA_NETWORK), "confirmed");
    // }
    connection = new web3.Connection('https://restless-quick-glade.solana-mainnet.discover.quiknode.pro/dccf206029046344bf6bb0ac2718c436d2e5f6ed', "confirmed");

    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(false);
    const [canVote, setCanVote] = useState(false);
    const [numVotes, setNumVotes] = useState(0);
    const [isVoting, setIsVoting] = useState(false);
    const [voteChoice, setVoteChoice] = useState(null);
    const [vote, setVote] = useState(null);
    const [error, setError] = useState('');
    const { wallet, publicKey } = useWallet();
    const anchorWallet = useAnchorWallet();
    const { setVisible } = useWalletModal();
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalClose = () => setModalOpen(false);
    const [showVote1, setShowVote1] = useState(false);
    const [showVote2, setShowVote2] = useState(false);
    const [showVote3, setShowVote3] = useState(false);
    const [showVote4, setShowVote4] = useState(false);
    const [showVote5, setShowVote5] = useState(false);
    const [showVote6, setShowVote6] = useState(false);
    const [showVote7, setShowVote7] = useState(false);

    const [potentialVote, setPotentialVote] = useState(null);
    const [potentialVoteImage, setPotentialVoteImage] = useState(null);
    
    const [voteOpen, setVoteOpen] = useState(false);
    const handleVoteClose = () => setVoteOpen(false);

    const [votedOpen, setVotedOpen] = useState(false);
    const handleVotedClose = () => setVotedOpen(false);

    const [errorOpen, setErrorOpen] = useState(false);
    const handleErrorClose = () => setErrorOpen(false);

    const VoteText = styled(Typography)({
        fontSize: '140px',
        lineHeight: '1',
    });

    const checkVoteStatus = async() => {
        console.log('check vote status');
        const provider = new AnchorProvider(
            connection, anchorWallet, opts.preflightCommitment,
        );
        const program = new Program(idl, programId, provider);

        const voterAccountPDA = await getVoterAccountPDA(programId);
        let voterAccount;

        try {
            voterAccount = await program.account.voterAccount.fetch(voterAccountPDA);
            console.log(voterAccount.vote.toNumber());
            setVote(voterAccount.vote.toNumber());
            setIsVoting(false);
            return true;
        } catch (err) {
            console.log('does not have voter account');
        }
    }

    const getVoterAccountPDA = async(programId) => {
        const [voterAccountPDA, _] = await web3.PublicKey
        .findProgramAddress([
            utils.bytes.utf8.encode("voter-account"),
            publicKey.toBuffer()
        ], programId);
        
        return voterAccountPDA;
    }

    const getCreateVoterAccountInstructions = async(voterAccountPDA, program) => {
        return await program.methods.createVoterAccount()
        .accounts({
            user: anchorWallet.publicKey,
            voterAccount: voterAccountPDA,
        })
        .instruction();
    }

    const getVoteObject = (auctionNum) => {
        let voteObj;
        auctionNum = parseInt(auctionNum);

            switch (auctionNum) {
                case 1: 
                voteObj = { auction1: {}};
                break;
            case 2:
                voteObj = { auction2: {}};
                break;
            case 3:
                voteObj = { auction3: {}};
                break;
            case 4:
                voteObj = { auction4: {}};
                break;
            case 5:
                voteObj = { auction5: {}};
                break;
            case 6:
                voteObj = { auction6: {}};
                break;
            case 7:
                voteObj = { auction7: {}};
                break;
            }
        return voteObj;
    }

    const getVoteAccountPDA = async(program) => {
            const [voteAccountPDA, voteAccountBump] = 
                await web3.PublicKey.findProgramAddress(
                    [Buffer.from("vote_account")],
                    program.programId,
                );
            return voteAccountPDA;
    }

    const getVoterListPDA = async(program) => {
        const [voterListAccount, voterListBump] = 
            await web3.PublicKey.findProgramAddress(
            [Buffer.from("voter_list")],
            program.programId,
        );

        return voterListAccount;
    }

    const getTokenAddress = async(mintKey) => {
        return await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintKey,
          publicKey,
        );
    }
    
    const getVaultPDA = async(vaultAuthority, program) => {
        const [vault] = await web3.PublicKey.findProgramAddress([
            utils.bytes.utf8.encode("vault"),
            vaultAuthority.toBuffer()
          ],
          program.programId);

        return vault;
    }

    function VoteBox(props) {
        return <Stack justifyContent="center" alignItems="center" style={{ height: 315  }}>
            <Box>
            {!authenticated || !canVote ? <span></span> : 
                !vote ? (
                    <Box>
                    <VoteButton onClick={() => handleVoteClick(props.auctionNum, props.imageState, props.stateChange)}>CAST VOTE!</VoteButton>
                    <h4 style={{ fontSize: 20, margin: 0 }}>& get some <span style={{ color: '#FFFFFF' }}>$creck</span></h4>
                    </Box>) : 
                         parseInt(vote) === parseInt(props.auctionNum) ? <h4 style={{ color: '#FFFFFF' }}>You voted for this</h4> : <h4 style={{ color: '#FFFFFF' }}>You voted for #{vote}</h4>
            }
            </Box>                                            
        </Stack>;
    }

    const submitVote = async(auctionNum) => {
        setIsVoting(true);
        console.log(`wallet pubkey`, publicKey.toString());
        console.log(`anchor wallet pubkey`, anchorWallet.publicKey.toString());
        console.log(`voting for auction${auctionNum}`);

        const provider = new AnchorProvider(
            connection, anchorWallet, opts.preflightCommitment,
        );
        const program = new Program(idl, programId, provider);
        
        const tx = new Transaction();
        
        let voterAccountPDA = await getVoterAccountPDA(programId);
        console.log(`voter account pda`, voterAccountPDA.toString());
      
        let voterAccount;

        try {
            voterAccount = await program.account.voterAccount.fetch(voterAccountPDA);
            console.log(`voter account`, voterAccount);
        } catch (err) {
            // this means it doesn't exist;
            console.log('no voter account! adding to instructions');
            const voterAccountTx = await getCreateVoterAccountInstructions(voterAccountPDA, program);
            tx.add(voterAccountTx);
        }

        // check if they have already voted
        if (voterAccount && voterAccount.count > 0) {
            console.log('already voted');
            setError('already voted');
            setVoteOpen(false);
            setErrorOpen(true);
            return;
        }

        let voteObj = getVoteObject(auctionNum);

        console.log(`vote object`, voteObj);
    
        let voteAccountPDA = await getVoteAccountPDA(program);
        console.log(`vote account pda`, voteAccountPDA.toString());

        let voterListPDA = await getVoterListPDA(program);
        console.log(`voter list pda`, voterListPDA.toString());

        const mintKey = new web3.PublicKey(process.env.REACT_APP_MINT_KEY);
        console.log(`mint key`, mintKey.toString());


        const [tokenAddress] = await PublicKey.findProgramAddress(
            [publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mintKey.toBuffer()],
            ASSOCIATED_TOKEN_PROGRAM_ID
        );
        console.log(`token address`, tokenAddress.toString());

        let tokenAccount;

        try {
            tokenAccount = await connection.getAccountInfo(tokenAddress);
        } catch (err) {
        }

        if (!tokenAccount) {
            console.log(`no token account, setting up`);
            tx.add(
                Token.createAssociatedTokenAccountInstruction(
                            ASSOCIATED_TOKEN_PROGRAM_ID,
                            TOKEN_PROGRAM_ID,
                            mintKey,
                            tokenAddress,
                            anchorWallet.publicKey,
                            anchorWallet.publicKey,
            ));

            // tx.add(ataTx);
                    
            // const latest = await provider.connection.getLatestBlockhash();
            // ataTx.recentBlockhash = latest.blockhash;
            // ataTx.feePayer = anchorWallet.publicKey;
            
            // const signedTx = await anchorWallet.signTransaction(ataTx);
            // const sentTx = await web3.sendAndConfirmRawTransaction(connection, signedTx.serialize(), 'confirmed');
            // console.log(`create token account tx`, sentTx);
            // tokenAccount = await connection.getAccountInfo(tokenAddress);

        } else {
            console.log(`token account`, tokenAccount);
        }

        const gibVoteTx = await program.methods.gibVote(voteObj)
            .accounts({
                voteAccount: voteAccountPDA,
                user: anchorWallet.publicKey,
                voterAccount: voterAccountPDA,
                voterList: voterListPDA,
                voterTokenAccount: tokenAddress,
            })            
            .instruction();
        tx.add(gibVoteTx);

        let vaultAuthority = new web3.PublicKey(process.env.REACT_APP_VAULT_AUTHORITY_KEY);
        
        const vaultPDA = await getVaultPDA(vaultAuthority, program);
        console.log(`vault pda`, vaultPDA.toString());

        // let vaultData = await program.account.vault.fetch(vaultPDA);
        // console.log(`vault data`, vaultData);
        // console.log(`vault tokens key`, vaultData.vaultTokens.toString());
        let vaultedTokensKey = new web3.PublicKey('5ipiGW2zyaBA7DFuGSw7t3cEJECNpAgbmQjEvdkkKjQL');

        // let vaultedTokensKey = vaultData.vaultTokens;

        const claimRewardTx = await program.methods.claimReward()
            .accounts({
                receiverAccount: voterAccountPDA,
                receiver: publicKey,
                vault: vaultPDA,
                vaultTokens: vaultedTokensKey,
                receiverTokens: tokenAddress,
                tokenProgram: TOKEN_PROGRAM_ID,
            })
            .instruction();
        tx.add(claimRewardTx);

        const latest = await connection.getLatestBlockhash();
        tx.recentBlockhash = latest.blockhash;
        tx.feePayer = anchorWallet.publicKey;

        console.log(`instructions`, tx);

        const signedTxs = await anchorWallet.signAllTransactions([tx]);
        console.log(`signed txs`, signedTxs);

        try {
            for (let sig in signedTxs) {
                console.log(`confirming tx ${sig}`);
                const sentTx = await web3.sendAndConfirmRawTransaction(connection, signedTxs[sig].serialize(), 'confirmed');
                console.log(`sent tx`, sentTx);
                console.log(`signed txs`, signedTxs[sig]);
            }

            console.log(`recording vote`);

        } catch (err) {
            setVoteOpen(false);
            setError(`ERROR`, err.message);
            setErrorOpen(true);
            console.log(err);
            return;
        }

        setVote(auctionNum);
        setVoteOpen(false);
        setVotedOpen(true);
        setIsVoting(false);
    }

    const handleVoteClick = async(auctionNum, auctionImage, stateChange) => {
        if (!publicKey) {
            setIsVoting(true);
            setPotentialVoteImage(auctionImage);
            setVoteChoice(auctionNum);
            setVisible(true);
            console.log('no pub key');
            return;
        }

        if (stateChange) {
            console.log('state change', stateChange);
            stateChange(false);
            setPotentialVoteImage(auctionImage);
            setPotentialVote(auctionNum);
            setVoteOpen(true);
        }

        if (isVoting) {
            console.log('open vote modal');
            setPotentialVoteImage(auctionImage);
            setPotentialVote(auctionNum);
            setVoteOpen(true);
        }
    }

    const downloadAsset = (imageNum) => {
        fetch(process.env.PUBLIC_URL +`/assets/auction1.png`, {
            method: "GET",
            headers: {}
          })
            .then(response => {
              response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
  
                link.setAttribute("download", `/assets/auction1.png`);
                document.body.appendChild(link);
                link.click();
              });
            })
            .catch(err => {
              console.log(err);
            });
        
        
        // alert('downloading')
    }

    return (
            <Box>
                 <Box position="absolute"  className='home-coc-toes' >
                <img src={cocToes1} />
            </Box>
            
            <Container align="center" className="vote-container coc-assets-vector">

   
            <Box position="absolute" className='home-oh-coc' >
                <img src={ohCoc} />
            </Box>

            <Box position="absolute" className='home-booty-coc' >
                <img src={bootyCoc} />
            </Box>

            <Box position="absolute" className='home-troll-coc' >
                <img src={trollCoc} />
            </Box>

            <Stack justifyContent="center" direction="row" >

                <Box mt={{ xs: 0, md: 8, lg: 0 }} position={'relative'}>
                    <Typography className='header3'>SHOW US YOUR $COC</Typography>

                    {/* <Box className='steps-container'>
                        <Typography className='steps-header'>STEP ONE</Typography>
                        <Typography className='steps-text'>1. Download Chiken below and make meme</Typography>

                        <Typography className='steps-header' mt={4}>STEP TWO</Typography>
                        <Typography className='steps-text'>2. Post on twitter using $COC and #meme2earn, then paste URL into meme Feed</Typography>

                        <Typography className='steps-header' mt={4}>STEP THREE</Typography>
                        <Typography className='steps-text'>3. Level up, earn $COC</Typography>
                    </Box> */}
                    {/* <h3>SHOW US YOUR $COC</h3> */}
                    <Box className='how-it-works'>
                        <Button onClick={() => setModalOpen(true)} >
                        <img src={howItWorks} />
                        </Button>
                    </Box>
                </Box>
            </Stack>
            
            <Box mt={{ xs: 16, sm: 28, md: 34, lg: 42 }} mb={24} position="relative">
            <Box position="relative">
                <Typography className='header3' style={{ zIndex: 4, position: 'relative' }}>MEME ASS-ETS</Typography>
                <Box position="absolute" className='cream-coc-container' >
                    <img src={creamCoc} width={200} />
                </Box>
            </Box>
         

            <Grid container spacing={3} mt={{ xs: 0, sm: 8 }} mb={12} className="auctions-container">
            <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction7-container">
                            <Box className={`auction-button auction7-hover auction7-button`}>
                                <a href={character7} download>
                                    <img src={character7} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character7} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction10-container">
                            <Box className={`auction-button auction10-hover auction10-button`}>
                                <a href={character10} download>
                                    <img src={character10} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character10} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction5-container">
                            <Box className={`auction-button auction5-hover auction5-button`}>
                                <a href={character5} download>
                                    <img src={character5} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character5} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                        </Box>
                </Grid>


                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction9-container">
                            <Box className={`auction-button auction9-hover auction9-button`}>
                                <a href={character9} download>
                                    <img src={character9} width="100%" />
                                 </a>
                            </Box>
                            <Box className="download-text">
                            <a href={character9} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction6-container">
                            <Box className={`auction-button auction6-hover auction6-button`}>
                                <a href={character6} download>
                                    <img src={character6} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character6} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction1-container">
                            <Box className={`auction-button auction1-hover auction1-button`}>
                                <a href={character1} download>
                                    <img src={character1} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                                <a href={character1} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>

                </Grid>
                
                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction8-container">
                            <Box className={`auction-button auction8-hover auction8-button`}>
                                <a href={character8} download>
                                    <img src={character8} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character8} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>
                </Grid>



                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction2-container">
                        <Box className={`auction-button auction2-hover auction2-button`}>
                                <a href={character2} download>
                                    <img src={character2} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                                <a href={character2} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className="auction-button-container auction3-container">
                        <Box className={`auction-button auction3-hover auction3-button`}>
                                <a href={character3} download>
                                    <img src={character3} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character3} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>

                            </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction4-container">
                            <Box className={`auction-button auction4-hover auction4-button`}>
                                <a href={character4} download>
                                    <img src={character4} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character4} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                    </Box>
                </Grid>
                


  



   

                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction11-container">
                            <Box className={`auction-button auction11-hover auction11-button`}>
                                <a href={character11} download>
                                    <img src={character11} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character11} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                        </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className={"auction-div"} sx={{ position: 'relative' }}>
                        <Box className="auction-button-container auction12-container">
                            <Box className={`auction-button auction12-hover auction12-button`}>
                                <a href={character12} download>
                                    <img src={character12} width="100%" />
                                 </a>
                            </Box>

                            <Box className="download-text">
                            <a href={character12} download>
                                    <Typography>DOWNLOAD PNG</Typography>
                                </a>
                            </Box>
                        </Box>
        
                </Grid>
            </Grid>
            </Box>
            {/* <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className="vote-character-box" >
                            <img src={booger2} width="175" />

                            <Box className="wtf-happening">
                            <img src={wtfHappening} width="175" style={{ position: 'relative', top: '10px' }} />
                    </Box>
                    </Box>
                </Grid>
            </Grid> */}
            <Modal open={modalOpen} onClose={handleModalClose}>
            <Box> 
                <Stack className='modal-connect' sx={{ ...modalStyle }} justifyContent="center" alignItems="center">
                    <Box sx={{ textAlign: 'center', transform: 'rotate(2deg)' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 30, md: 48 }}>HOW IT WORKS</Typography>
                    </Box>
                    
                    <Box sx={{ textAlign: 'left', width: '100%', transform: 'rotate(-2deg)' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 16, md: 20 }} color={'#FFD400'}>STEP 1</Typography>
                        <Typography component={'h4'} fontSize={{ xs: 20, md: 30 }}>DOWNLOAD CHIKEN AND MAKE MEME</Typography>
                    </Box>

                    <Box sx={{ textAlign: 'left', width: '100%' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 16, md: 20 }} color={'#FFD400'}>STEP 2</Typography>
                        <Typography component={'h4'} fontSize={{ xs: 20, md: 30 }}>POST ON TWITTER USING $COC AND #MEME2EARN AND PASTE THE URL INTO THE CHIKEN FEED</Typography>
                    </Box>

                    <Box sx={{ textAlign: 'left', width: '100%' }} mb={6}>
                        <Typography component={'h4'} fontSize={{ xs: 16, md: 20 }} color={'#FFD400'}>STEP 3</Typography>
                        <Typography component={'h4'} fontSize={{ xs: 20, md: 30 }}>GET POINTS, EARN COC</Typography>
                    </Box>

                    <Box sx={{ display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap' }}>
                        <CloseButton onClick={handleModalClose} variant="contained" size="large" disableElevation>
                            <Typography component="span">{'Ok, gotcha. lets make memes'}</Typography>
                            {/* <img src={cocTwitter} width="31" /> */}
                        </CloseButton>
                    </Box>


                <StyledButton variant="text" sx={{ position: 'absolute', right: 6, top: 10 }} onClick={handleModalClose}>
                    <Typography component={'h4'} fontSize={30}>X</Typography>

                </StyledButton>
                </Stack>

                </Box>
            </Modal>
        </Container>
       
        </Box>
    )
}

export default Vote;