import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import ProfileItem from '../components/ProfileItem';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import boogerCharacter from '../assets/leaderboard-character.png';
import mobileBoogerCharacter from '../assets/leaderboard-mobile-character.png';
import youWinning from '../assets/you-winning.png';
import leaderboardHeader from '../assets/leaderboard-header.png';
import nFormatter from '../helpers/numberFormatter';
import { red } from '@mui/material/colors';
import cocToes from '../assets/coc-toes.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: '1.125rem',
      fontWeight: 400,
      border: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: '#FFF',
        border: 0,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    [`&.${tableCellClasses.body}:first-child`]: {
        borderTopLeftRadius: '50px',
        borderBottomLeftRadius: '50px',
        border: '2px solid black',
        borderRight: 0,
        boxShadow: '4px 4px black',
    },
    [`&.${tableCellClasses.body}:nth-child(3)`]: {
        // borderTopRightRadius: '50px',
        // borderBottomRightRadius: '50px',
        border: '2px solid black',
        borderLeft: 0,
        boxShadow: '4px 4px black',
    },
    [`&.${tableCellClasses.body}:nth-child(2)`]: {
        border: '2px solid black',
        borderLeft: 0,
        borderRight: 0,
        boxShadow: '4px 4px black',
        paddingLeft: 24,
    },
    [`&.${tableCellClasses.body}:last-child`]: {
        // borderTopRightRadius: '50px',
        // borderBottomRightRadius: '50px',
        background: 'transparent',
    },
  }));

  const NotisTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        // backgroundColor: '#f2f2f2',
        border: 0,
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    [`&.${tableCellClasses.body}:last-child`]: {
        // backgroundColor: '#ffffff',
    },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function Leaderboard() {
    const [leaders, setLeaders] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [value, setValue] = React.useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const navigate = useNavigate();

    useEffect( () => {
        async function fetchLeaders() {
            const url = `${process.env.REACT_APP_MEME_API_URL}/leaderboard`;
            const resp = await fetch(url);
            const respJson = await resp.json();
            console.log(`leaders`, respJson);

            setLeaders(respJson);
        }

        async function fetchProfiles() {
            const url = `${process.env.REACT_APP_MEME_API_URL}/profiles`;
            const resp = await fetch(url);
            const respJson = await resp.json();
            console.log(`profiles`, respJson);
            
            setProfiles(respJson);
        }

        fetchLeaders();
        fetchProfiles();
     }, []);

    const pfp = (url) => {
        return url.replace('_normal.', '_bigger.');
    }

    const handleProfileClick = (profile) => {
        console.log(`profile click`, profile);
        navigate(`/profile/${profile.username}`);
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
     const rankingsDiv =()=>{
        return<>
    
    <Table className="profile-header-table" sx={{ borderCollapse: 'separate', borderSpacing: '0px 10px', height: 'max-content' }} stickyHeader>
    <Box className='coc-toes-container'>
            <img src={cocToes} />
        </Box>
    <TableHead>
                <TableRow>
                    <StyledTableCell sx={{ paddingBottom: 0, background: 'transparent', color: 'white' }} className="mobile-text"><Typography fontSize="24px"># Rank</Typography></StyledTableCell>
                    <StyledTableCell sx={{ paddingBottom: 0, background: 'transparent', color: 'white', paddingLeft: 0  }} className="mobile-text"><Typography fontSize="24px">Profile + Username</Typography></StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'right', paddingBottom: 0, background: 'transparent', color: 'white'  }} className="mobile-text"><Typography fontSize="24px">Total Memes</Typography></StyledTableCell>
                    <StyledTableCell sx={{ minWidth: '0', background: 'transparent', color: 'white'  }}></StyledTableCell>
                </TableRow>
    </TableHead>
    </Table>
    <TableContainer className="tbl-scroll" component={Box} sx={{ overflowY: 'scroll', display: 'block', zIndex: 10, position: 'relative', top: -10 }}>
        <Table className="profile-table" sx={{ borderCollapse: 'separate', borderSpacing: '0px 10px', height: 'max-content' }} stickyHeader>
            {/* <TableHead>
                <TableRow>
                    <StyledTableCell>#RANK</StyledTableCell>
                    <StyledTableCell>PROFILE + USERNAME</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'right' }}>TOTAL MEMES</StyledTableCell>
                </TableRow>
            </TableHead> */}
            <TableBody>
                {leaders.map((item, index) => (
                    <StyledTableRow
                        key={'leader'+index}
                        onClick={() => handleProfileClick(item)}
                        hover 
                        className="leader-row"
                    >
                        <StyledTableCell sx={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16 }}>
                            <Avatar sx={{ backgroundColor: '#F00600', width: 33, height: 33, border: '2px solid black', boxShadow: '2px 2px black', WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '1px', fontSize: '1.7rem' }}>{index + 1}</Avatar>
                        </StyledTableCell>
                        <StyledTableCell className="leader-profile">
                            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '16px' }} justifyContent="flex-start">
                                <Avatar sx={{ width: 48, height: 48, mr: 2 }} src={pfp(item.profileImg)} variant="rounded" />
                                <Typography
                                    fontSize={'1.5rem'}
                                    variant="span">
                                        {item.name} 
                                        <Typography 
                                            variant="span"
                                            className="leader-username">
                                            @{item.username}
                                        </Typography>
                                    </Typography>
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell sx={{ borderTopRightRadius: 16, borderBottomRightRadius: 16, textAlign: 'center' }}>
                            <Typography
                                variant="span"
                                sx={{ fontSize: '2rem' }}>
                                {nFormatter(parseInt(item.memeCount))}
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: '0', }}>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    </>
    }
    const notificationDiv = ()=>{
        return<>
                    <TableContainer className="tbl-scroll notis-table" component={Box} sx={{  }}>
                        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 10px', height: 'max-content' }}>
                            <TableBody>
                                {profiles.map((item, index) => (
                                    <StyledTableRow
                                        key={'profile'+index}
                                    >
                                        <NotisTableCell sx={{ borderTopLeftRadius: 40, borderBottomLeftRadius: 40, paddingLeft: 4 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
                                                <Avatar className="notis-avatar" sx={{ width: 28, height: 28, mr: 2 }} src={item.profileImg} variant="rounded" />
                                                <Typography
                                                    className="notis-username"
                                                    variant="span"
                                                    sx={{ color: '#CD615D', fontSize: '0.8rem' }}>
                                                        @{item.username} 
                                                        <Typography 
                                                            fontSize={'1rem'}
                                                            className="notis-comment"
                                                            variant="span"
                                                            sx={{ pl: 1, color: '#000000' }}>
                                                            {item.type === 'created' ? 'submitted a meme' : 'meme was approved'}
                                                        </Typography>
                                                    </Typography>
                                            </Box>
                                        </NotisTableCell>
                                        <NotisTableCell sx={{ borderTopRightRadius: 40, borderBottomRightRadius: 40 }}>
                                            <Typography
                                                variant="span"
                                                sx={{ color: '#CD615D', fontSize: '0.875rem' }}>
                                                {item.type === 'created' ? moment(item.createdAt).fromNow() : moment(item.approvalDate).fromNow()}
                                            </Typography>
                                        </NotisTableCell>
                                        <NotisTableCell sx={{ minWidth: '4px' }}>
                                        </NotisTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
        </>
    }
    return (
        <div>
            <Container className={"leaderboard-container coc-leader-vector"} align="center">
                {/* <Stack direction="row" sx={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}>  */}
                    {/* <img width="292" className={"leaderboardHeader-img"} src={leaderboardHeader} /> */}
                    {/* <img className={"youWinning-img"} src={youWinning} /> */}
                    <Box display="flex" justifyContent="center" mt={{ xs: 14, lg: 12 }}>
                        <Typography className='header3'>MEMERBOARD</Typography>
                    </Box>

                {/* </Stack> */}
            
                {/* <Box sx={{ position: 'absolute', right: 0, top: '100px' }}>
                    <img src={boogerCharacter} className="boogerCharacter-leaderboard" width="250" />
                </Box>

                <Box sx={{ position: 'absolute', right: 0, top: '80px' }}>
                    <img src={mobileBoogerCharacter} className="mobile-boogerCharacter-leaderboard" width="125" />
                </Box> */}

      <Box maxWidth={{ xs: "90%", sm: "85%", lg: "70%" }} position="relative">


      <Box display="flex" mt={15} sx={{ textAlign: 'left' }}>

                {/* <Box sx={{ textAlign: 'left' }}> */}
        <Typography
            variant="h4"
            fontWeight="400"
            fontSize="1.8rem"
        >RANKINGS </Typography>
    </Box>
                 {rankingsDiv()}

             
                </Box>
            </Container>
        </div>
    )
}

export default Leaderboard;