import { TwitterTweetEmbed } from 'react-twitter-embed';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

function Loader() {
    return (
        <Box sx={{ paddingTop: '11' }} align="left">
            <Skeleton sx={{ bgcolor: 'grey.700', marginBottom: '1em' }} variant="circular" width={46} height={46} />
            <Skeleton sx={{ bgcolor: 'grey.700', fontSize: '2rem', marginBottom: '0.5em' }} />
            <Skeleton sx={{ bgcolor: 'grey.700', fontSize: '2rem', marginBottom: '0.75em' }} />
            <Skeleton sx={{ bgcolor: 'grey.700' }} variant="rounded" height={300} />
        </Box>
    );
}

function MemeTweet({ tweetId }) {
    return (
        <div>
            <TwitterTweetEmbed
                onLoad={function noRefCheck(){ }}
                options={{ theme: "dark", width: "300", dnt: true, conversation: 'none' }} 
                // placeholder={<div style={{backgroundColor: 'red', color: 'white', width: '300', padding: 10}}>Loading</div>}
                placeholder={<Loader />}
                tweetId={tweetId}
            />
        </div>
    );
}

export default MemeTweet;