import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function MemeImage(props) {
    const [loaded, setLoaded] = useState(false);

    return (
        <div>
        {!loaded && <Skeleton sx={{ height: '600px', bgcolor: 'grey.700', fontSize: '2rem', marginBottom: '0.5em' }} />}
            <a href={props.memeUrl} target="_blank" className='TwitterTweetEmbed-div'>
                <img onLoad={() => setLoaded(true)} className={"TwitterTweetEmbed"} width="100%" loading="lazy" style={{ marginTop: '10px' }} src={props.memeImg} /></a>
        </div>
    );
}

export default MemeImage;