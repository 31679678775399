import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MemeTweet from '../components/MemeTweet';
import Cookies from 'js-cookie';

function Approve() {
    const [unapproved, setUnapproved] = useState([]);
 
    const fetchUnapproved = async() => {
        const url = `${process.env.REACT_APP_MEME_API_URL}/unapproved`;
        const resp = await fetch(url);
        const respJson = await resp.json();
        console.log(`unapproved`, respJson);
        setUnapproved(respJson);
    }

    const approveMeme = async(memeId) => {
        console.log(`approving ${memeId}`);
        const url = `${process.env.REACT_APP_MEME_API_URL}/approve/${memeId}`;
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` },
        };
        const resp = await fetch(url, options);
        await fetchUnapproved();
    }

    const declineMeme = async(memeId) => {
        console.log(`declining ${memeId}`);
        const url = `${process.env.REACT_APP_MEME_API_URL}/decline/${memeId}`;
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` },
        };
        const resp = await fetch(url, options);
        await fetchUnapproved();
    }

    useEffect( () => {
        fetchUnapproved();
     }, []);

    return (
        <div>
            <Container align="center">
                <h3>Approval Portal</h3>
                {unapproved.length === 0 && <Box><h3>No results found.</h3></Box>}
                {unapproved.map(item => {
                    if (item.id !== 31 && item.id !== 33 && item.id !== 34) {
                    return (
                        <Box key={item.tweetId} sx={{ width: 320, backgroundColor: 'grey.800', paddingTop: 2, paddingBottom: 2, marginBottom: 2 }}>
                            <Button sx={{ marginRight: 2 }} variant="contained" onClick={() => approveMeme(item.id)}>Approve</Button>
                            <Button variant="contained" onClick={() => declineMeme(item.id)}>Decline</Button>

                            <Box>
                                <MemeTweet tweetId={item.tweetId} />
                            </Box>

                        </Box>
                    )}
                })}
            </Container>
        </div>
    )
}

export default Approve;