import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import QuirkySunflower from './assets/fonts/QuirkySunflower/quirkysunflower-webfont.woff';
import TinyFont from './assets/fonts/TinyFont/TINY5x3-140.woff';
import ShadowsIntoLight from './assets/fonts/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf';
import SpaceGrotesk from './assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf';
import ChickenScratch from './assets/fonts/ChickenScratch.ttf';
import MoreSugar from './assets/fonts/more-sugar/MoreSugar-Thin.ttf';
import FriedChicken from './assets/fonts/fried-chicken/FriedChicken.ttf';

// Red - #DA3412
// Yellow - #F8BE22
// Green - #0E8F57


const theme = createTheme({
  typography: {
    fontFamily: 'Fried Chicken',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Quirky Sunflower';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${QuirkySunflower}) format('woff')
        }

        @font-face {
          font-family: 'ShadowsIntoLight';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${ShadowsIntoLight}) format('truetype')
        }

        @font-face {
          font-family: 'Tiny Font';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${TinyFont}) format('woff')
        }

        @font-face {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${SpaceGrotesk}) format('truetype')
        }

        @font-face {
          font-family: 'Chicken Scratch';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${ChickenScratch}) format('truetype')
        }

        @font-face {
          font-family: 'More Sugar';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${MoreSugar}) format('truetype')
        }

        @font-face {
          font-family: 'Fried Chicken';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${FriedChicken}) format('truetype')
        }
      `,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;