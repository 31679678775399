import React, { useMemo } from "react";

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider, WalletDisconnectButton, WalletMultiButton, WalletModal } from '@solana/wallet-adapter-react-ui';

import { clusterApiUrl, Connection } from '@solana/web3.js';

require("@solana/wallet-adapter-react-ui/styles.css");

const WalletContainer = () => {
    // const network = WalletAdapterNetwork.Devnet;
    const network = "http://127.0.0.1:8899";
    const connection = new Connection(network);

    // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
          new PhantomWalletAdapter(),
        ],
        [network]
    );

    return (
        <div>
            <ConnectionProvider endpoint={network}>
                <WalletProvider wallets={wallets}>
                    <WalletModalProvider>
                        <WalletModal />
                        {/* <WalletMultiButton /> */}
                        {/* <WalletDisconnectButton /> */}
                    </WalletModalProvider>
                </WalletProvider>
            </ConnectionProvider>
        </div>
    );
};
export default WalletContainer;