import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { Login, SettingsInputComponent, SettingsPowerRounded } from '@mui/icons-material';
import TwitterIcon from '@mui/icons-material/Twitter';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import cocTwitter from '../assets/coc-twitter.png';
import { Box } from '@mui/material';

function TwitterLogin({loginStatus, signedInUser, returnPath, isMobile}) {
    const [user, setUser] = useState({});
    const [error, setError] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        if (!isMobile) {
            const connectedButton = document.querySelector('.connected-container');
            setAnchorEl(connectedButton);
        } else {
            setAnchorEl(e.currentTarget.parentNode);
        }
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    useEffect(() => {
        async function getStatus() {
            const resp = await fetch(`${process.env.REACT_APP_MEME_API_URL}/auth/login/success?returnPath=${returnPath}`, {
                method: "GET",
                credentials: "include",
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true
                }
                });
            if (resp.status !== 200) {
                console.log("Failed to authenticate user");
                setAuthenticated(false);
                setUser({});
                signedInUser({});
                loginStatus(false);
                return;
            }

            const jsonResp = await resp.json();
            console.log(jsonResp);
            setUser(jsonResp.user);
            signedInUser(jsonResp.user);
            setAuthenticated(true);
            loginStatus(true);

        }

        getStatus();
    }, [user.id]);

    const ConnectLink = styled(Link)({
        // backgroundColor: '#000000',
        borderRadius: 30,
        // color: '#FFFFFF',

        '&:hover': {
            backgroundColor: '#B5C6FF',
          },
    });

    const loginUrl = `${process.env.REACT_APP_MEME_API_URL}/auth/twitter?redirect=${location.pathname}`;
    const logoutUrl = `${process.env.REACT_APP_MEME_API_URL}/auth/logout?redirect=${location.pathname}`;

    const navigateToProfile = () => {
        handleClose();
        navigate(`/profile/${user.username}`);
    }

    const logoutTwitter = () => {
        handleClose();
        window.open(logoutUrl, '_self');
    }

    const walletText = () => {
        if (user.wallet.length) {
            let first = user.wallet.slice(0, 4);
            let last = user.wallet.slice(-3);

            return `wallet - ${first}..${last}`;
        }
    }

    return authenticated ?
    <div className="connected-container" id="connectedButtonContainer">
    <ConnectLink className={"navbar-twitter"} onClick={handleClick} style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    color: '#000000',
                    padding: '0.5em 1em',
                    // backgroundColor: '#B5C6FF',
                    backgroundColor: '#FFF',
                    border: '2px solid #000',
                    boxShadow: '4px 4px #000',
                }}>
                    <Box display={{ xs: 'none', sm: 'block' }} >
                        <Typography component="span" pr={2} sx={{ WebkitTextStrokeWidth: 0, textShadow: 0 }}>@{user.username}</Typography>
                    </Box>
                    <img src={cocTwitter} width="31" />
                    {/* <span>@{user.username}</span>
                    <TwitterIcon sx={{ ml: 1, color: '#000000' }} /> */}
                    </ConnectLink>

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        className="connected-menu"
                        disableAutoFocusItem={true}
                    >
                        <MenuItem onClick={navigateToProfile}>Profile</MenuItem>
                        <MenuItem onClick={logoutTwitter}>Disconnect Twitter</MenuItem>
                        {user.wallet && user.wallet.length > 0 && <MenuItem>{walletText()}</MenuItem>}
                    </Menu>

               </div> : 
                    <ConnectLink className={"navbar-twitter"} href={loginUrl} style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    color: '#000',
                    padding: '0.5em 1em',
                    background: '#fff',
                    border: '2px solid #000',
                    boxShadow: '4px 4px 0px 0px rgba(0, 0, 0, 1)',
                    textDecoration: 'none',

                }}>
                    
                    <Box display={{ xs: 'none', sm: 'block' }}>
                        <Typography component="span" pr={2}>CONNECT TWITTER</Typography>
                    </Box>
                    <img src={cocTwitter} width="31" />
                    {/* <TwitterIcon sx={{ ml: 1, color: '#FFFFFF' }} /> */}
                </ConnectLink>;
            // authenticated ? ( 
            //     <Link onClick={handleLogout}>Logout</Link> ) : 
            //     ( 
                    

            //     )}
    // )
}

export default TwitterLogin;