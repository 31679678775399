import React, { useEffect, useState, useMemo } from 'react';
import { PublicKey, Transaction, clusterApiUrl } from '@solana/web3.js';
import {
    Program, AnchorProvider, web3, utils,
  } from '@project-serum/anchor';
import idl from '../assets/idl/onchain_voting.json';
  
import { useWallet, useConnection, useAnchorWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
// import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { Token, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import loser from '../assets/loser.png';
import selectAuction from '../assets/select-auction.png';
import mobileSelectAuction from '../assets/mobile-select-auction.png';
import dontForget from '../assets/dont-forget.png';
import wtfHappening from '../assets/wtf-happening.png';
import booger1 from '../assets/auction-booger1.png';
import booger1Mobile from '../assets/booger-mobile.png';
import booger2 from '../assets/auction-booger2.png';
import xIcon from '../assets/x-icon.png';
import xIconLarge from '../assets/x-icon-large.png';
import blueCheck from '../assets/blue-check.png';
import blueCheckLarge from '../assets/blue-check-large.png';
import auction1 from '../assets/auction1.png';
import auction2 from '../assets/auction2.png';
import auction3 from '../assets/auction3.png';
import auction4 from '../assets/auction4.png';
import auction5 from '../assets/auction5.png';
import auction6 from '../assets/auction6.png';
import auction7 from '../assets/auction7.png';
import closeButton from '../assets/close-button.png';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

const programId = new PublicKey(idl.metadata.address);
const opts = {
    preflightCommitment: "processed"
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 560,
    bgcolor: '#FFFFFF',
    borderRadius: 4,
    paddingX: '60px',
    paddingY: '40px',
  };

const VoteButton = styled(Button)({
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: 40,
    fontSize: 28,
    border: '3px solid #000000',
    paddingLeft: '1em',
    paddingRight: '1em',
    '&:hover': {
        backgroundColor: '#B5C6FF',
      },
});

const SubmitVoteButton = styled(Button)({
    backgroundColor: '#000000',
    color: '#ffffff',
    borderRadius: 40,
    fontSize: '14px',
    fontFamily: 'Space Grotesk',
    fontWeight: '700',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#B5C6FF',
      },
});

const StyledButton = styled(Button)({
    '&:hover': {
        opacity: 0.7,
        transitionProperty: 'opacity',
        transitionTiming: '0.5s',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
});

const network = "http://127.0.0.1:8899";

function isTouchEnabled() {
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );
}

function Vote(props) {
    let connection;

    // if (!process.env.REACT_APP_SOLANA_NETWORK) {
    //     connection = new web3.Connection(network, "confirmed");
    // } else {
    //     connection = new web3.Connection(clusterApiUrl(process.env.REACT_APP_SOLANA_NETWORK), "confirmed");
    // }
    connection = new web3.Connection('https://restless-quick-glade.solana-mainnet.discover.quiknode.pro/dccf206029046344bf6bb0ac2718c436d2e5f6ed', "confirmed");

    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(false);
    const [canVote, setCanVote] = useState(false);
    const [numVotes, setNumVotes] = useState(0);
    const [isVoting, setIsVoting] = useState(false);
    const [voteChoice, setVoteChoice] = useState(null);
    const [vote, setVote] = useState(null);
    const [error, setError] = useState('');
    const { wallet, publicKey } = useWallet();
    const anchorWallet = useAnchorWallet();
    const { setVisible } = useWalletModal();

    const [showVote1, setShowVote1] = useState(false);
    const [showVote2, setShowVote2] = useState(false);
    const [showVote3, setShowVote3] = useState(false);
    const [showVote4, setShowVote4] = useState(false);
    const [showVote5, setShowVote5] = useState(false);
    const [showVote6, setShowVote6] = useState(false);
    const [showVote7, setShowVote7] = useState(false);

    const [potentialVote, setPotentialVote] = useState(null);
    const [potentialVoteImage, setPotentialVoteImage] = useState(null);
    
    const [voteOpen, setVoteOpen] = useState(false);
    const handleVoteClose = () => setVoteOpen(false);

    const [votedOpen, setVotedOpen] = useState(false);
    const handleVotedClose = () => setVotedOpen(false);

    const [errorOpen, setErrorOpen] = useState(false);
    const handleErrorClose = () => setErrorOpen(false);

    const VoteText = styled(Typography)({
        fontSize: '140px',
        lineHeight: '1',
    });

    useEffect(() => {
        // console.log(authenticated);        
        // console.log(props.authenticated);

        setAuthenticated(props.authenticated);
    }, [props]);

    useEffect(() => {        
        async function getVotingStatus() {
            const jwt = Cookies.get('token');
            if (jwt) {
                const decoded = jwt_decode(jwt);
                

                if (decoded.permissions && decoded.permissions.includes('can_vote')) {
                    setCanVote(true);
                    return;
                }

                const resp = await fetch(`${process.env.REACT_APP_MEME_API_URL}/vote/status/${decoded.username}`);
                const jsonResp = await resp.json();
                if (jsonResp.canVote && jsonResp.canVote === true) {
                    setCanVote(true);
                }
            }
        }

        // get number of votes
        async function getVoteCount() {
            const resp = await fetch(`${process.env.REACT_APP_MEME_API_URL}/vote/totals`);
            const jsonResp = await resp.json();
            if (jsonResp.count) {
                setNumVotes(jsonResp.count);
            }

            // console.log(`vote res`, jsonResp);
        }

        getVoteCount();
        getVotingStatus();

        var body = document.body;
        const html = document.documentElement;

        let height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
        height += 24;

        const votingBg = document.querySelector('.voting-bg');
        if (votingBg) {
            votingBg.style.height = `${height}px`;
        }

        return () => {
            votingBg.style.height = `100%`;
        }
    }, []);

    useEffect(() => {
        async function handleWalletConnect() {
        if (publicKey) {
                console.log('connected!!!!');
                console.log(publicKey);
                // setVisible(false);
                const alreadyVoted = await checkVoteStatus();
                if (voteChoice && !vote && isVoting && !alreadyVoted) {
                    setPotentialVote(voteChoice);
                    setVoteOpen(true);
                }
            } else {
                if (authenticated) {
                    setVisible(true);
                }
            }
        }
        handleWalletConnect();
    }, [publicKey]);

    const checkVoteStatus = async() => {
        console.log('check vote status');
        const provider = new AnchorProvider(
            connection, anchorWallet, opts.preflightCommitment,
        );
        const program = new Program(idl, programId, provider);

        const voterAccountPDA = await getVoterAccountPDA(programId);
        let voterAccount;

        try {
            voterAccount = await program.account.voterAccount.fetch(voterAccountPDA);
            console.log(voterAccount.vote.toNumber());
            setVote(voterAccount.vote.toNumber());
            setIsVoting(false);
            return true;
        } catch (err) {
            console.log('does not have voter account');
        }
    }

    useEffect(() => {
        

        async function updateWallet() {
            let jwt = Cookies.get('token');

            if (jwt) {
                const url = `${process.env.REACT_APP_MEME_API_URL}/vote/wallet`;
                const options = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` },
                    body: JSON.stringify({
                        wallet: anchorWallet.publicKey.toString()
                    }),
                };
                const resp = await fetch(url, options);
            }
        }

        if (anchorWallet) {
            updateWallet();
            checkVoteStatus();
        }
    }, [anchorWallet]);

    const showVoteOption = () => {
        return authenticated && canVote && !vote;
    }

    const getVoterAccountPDA = async(programId) => {
        const [voterAccountPDA, _] = await web3.PublicKey
        .findProgramAddress([
            utils.bytes.utf8.encode("voter-account"),
            publicKey.toBuffer()
        ], programId);
        
        return voterAccountPDA;
    }

    const getCreateVoterAccountInstructions = async(voterAccountPDA, program) => {
        return await program.methods.createVoterAccount()
        .accounts({
            user: anchorWallet.publicKey,
            voterAccount: voterAccountPDA,
        })
        .instruction();
    }

    const getVoteObject = (auctionNum) => {
        let voteObj;
        auctionNum = parseInt(auctionNum);

            switch (auctionNum) {
                case 1: 
                voteObj = { auction1: {}};
                break;
            case 2:
                voteObj = { auction2: {}};
                break;
            case 3:
                voteObj = { auction3: {}};
                break;
            case 4:
                voteObj = { auction4: {}};
                break;
            case 5:
                voteObj = { auction5: {}};
                break;
            case 6:
                voteObj = { auction6: {}};
                break;
            case 7:
                voteObj = { auction7: {}};
                break;
            }
        return voteObj;
    }

    const getVoteAccountPDA = async(program) => {
            const [voteAccountPDA, voteAccountBump] = 
                await web3.PublicKey.findProgramAddress(
                    [Buffer.from("vote_account")],
                    program.programId,
                );
            return voteAccountPDA;
    }

    const getVoterListPDA = async(program) => {
        const [voterListAccount, voterListBump] = 
            await web3.PublicKey.findProgramAddress(
            [Buffer.from("voter_list")],
            program.programId,
        );

        return voterListAccount;
    }

    const getTokenAddress = async(mintKey) => {
        return await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintKey,
          publicKey,
        );
    }
    
    const getVaultPDA = async(vaultAuthority, program) => {
        const [vault] = await web3.PublicKey.findProgramAddress([
            utils.bytes.utf8.encode("vault"),
            vaultAuthority.toBuffer()
          ],
          program.programId);

        return vault;
    }

    function VoteBox(props) {
        return <Stack justifyContent="center" alignItems="center" style={{ height: 315  }}>
            <Box>
            {!authenticated || !canVote ? <span></span> : 
                !vote ? (
                    <Box>
                    <VoteButton onClick={() => handleVoteClick(props.auctionNum, props.imageState, props.stateChange)}>CAST VOTE!</VoteButton>
                    <h4 style={{ fontSize: 20, margin: 0 }}>& get some <span style={{ color: '#FFFFFF' }}>$creck</span></h4>
                    </Box>) : 
                         parseInt(vote) === parseInt(props.auctionNum) ? <h4 style={{ color: '#FFFFFF' }}>You voted for this</h4> : <h4 style={{ color: '#FFFFFF' }}>You voted for #{vote}</h4>
            }
            </Box>                                            
        </Stack>;
    }

    const submitVote = async(auctionNum) => {
        setIsVoting(true);
        console.log(`wallet pubkey`, publicKey.toString());
        console.log(`anchor wallet pubkey`, anchorWallet.publicKey.toString());
        console.log(`voting for auction${auctionNum}`);

        const provider = new AnchorProvider(
            connection, anchorWallet, opts.preflightCommitment,
        );
        const program = new Program(idl, programId, provider);
        
        const tx = new Transaction();
        
        let voterAccountPDA = await getVoterAccountPDA(programId);
        console.log(`voter account pda`, voterAccountPDA.toString());
      
        let voterAccount;

        try {
            voterAccount = await program.account.voterAccount.fetch(voterAccountPDA);
            console.log(`voter account`, voterAccount);
        } catch (err) {
            // this means it doesn't exist;
            console.log('no voter account! adding to instructions');
            const voterAccountTx = await getCreateVoterAccountInstructions(voterAccountPDA, program);
            tx.add(voterAccountTx);
        }

        // check if they have already voted
        if (voterAccount && voterAccount.count > 0) {
            console.log('already voted');
            setError('already voted');
            setVoteOpen(false);
            setErrorOpen(true);
            return;
        }

        let voteObj = getVoteObject(auctionNum);

        console.log(`vote object`, voteObj);
    
        let voteAccountPDA = await getVoteAccountPDA(program);
        console.log(`vote account pda`, voteAccountPDA.toString());

        let voterListPDA = await getVoterListPDA(program);
        console.log(`voter list pda`, voterListPDA.toString());

        const mintKey = new web3.PublicKey(process.env.REACT_APP_MINT_KEY);
        console.log(`mint key`, mintKey.toString());


        const [tokenAddress] = await PublicKey.findProgramAddress(
            [publicKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mintKey.toBuffer()],
            ASSOCIATED_TOKEN_PROGRAM_ID
        );
        console.log(`token address`, tokenAddress.toString());

        let tokenAccount;

        try {
            tokenAccount = await connection.getAccountInfo(tokenAddress);
        } catch (err) {
        }

        if (!tokenAccount) {
            console.log(`no token account, setting up`);
            tx.add(
                Token.createAssociatedTokenAccountInstruction(
                            ASSOCIATED_TOKEN_PROGRAM_ID,
                            TOKEN_PROGRAM_ID,
                            mintKey,
                            tokenAddress,
                            anchorWallet.publicKey,
                            anchorWallet.publicKey,
            ));

            // tx.add(ataTx);
                    
            // const latest = await provider.connection.getLatestBlockhash();
            // ataTx.recentBlockhash = latest.blockhash;
            // ataTx.feePayer = anchorWallet.publicKey;
            
            // const signedTx = await anchorWallet.signTransaction(ataTx);
            // const sentTx = await web3.sendAndConfirmRawTransaction(connection, signedTx.serialize(), 'confirmed');
            // console.log(`create token account tx`, sentTx);
            // tokenAccount = await connection.getAccountInfo(tokenAddress);

        } else {
            console.log(`token account`, tokenAccount);
        }

        const gibVoteTx = await program.methods.gibVote(voteObj)
            .accounts({
                voteAccount: voteAccountPDA,
                user: anchorWallet.publicKey,
                voterAccount: voterAccountPDA,
                voterList: voterListPDA,
                voterTokenAccount: tokenAddress,
            })            
            .instruction();
        tx.add(gibVoteTx);

        let vaultAuthority = new web3.PublicKey(process.env.REACT_APP_VAULT_AUTHORITY_KEY);
        
        const vaultPDA = await getVaultPDA(vaultAuthority, program);
        console.log(`vault pda`, vaultPDA.toString());

        // let vaultData = await program.account.vault.fetch(vaultPDA);
        // console.log(`vault data`, vaultData);
        // console.log(`vault tokens key`, vaultData.vaultTokens.toString());
        let vaultedTokensKey = new web3.PublicKey('5ipiGW2zyaBA7DFuGSw7t3cEJECNpAgbmQjEvdkkKjQL');

        // let vaultedTokensKey = vaultData.vaultTokens;

        const claimRewardTx = await program.methods.claimReward()
            .accounts({
                receiverAccount: voterAccountPDA,
                receiver: publicKey,
                vault: vaultPDA,
                vaultTokens: vaultedTokensKey,
                receiverTokens: tokenAddress,
                tokenProgram: TOKEN_PROGRAM_ID,
            })
            .instruction();
        tx.add(claimRewardTx);

        const latest = await connection.getLatestBlockhash();
        tx.recentBlockhash = latest.blockhash;
        tx.feePayer = anchorWallet.publicKey;

        console.log(`instructions`, tx);

        const signedTxs = await anchorWallet.signAllTransactions([tx]);
        console.log(`signed txs`, signedTxs);

        try {
            for (let sig in signedTxs) {
                console.log(`confirming tx ${sig}`);
                const sentTx = await web3.sendAndConfirmRawTransaction(connection, signedTxs[sig].serialize(), 'confirmed');
                console.log(`sent tx`, sentTx);
                console.log(`signed txs`, signedTxs[sig]);
            }

            console.log(`recording vote`);

        } catch (err) {
            setVoteOpen(false);
            setError(`ERROR`, err.message);
            setErrorOpen(true);
            console.log(err);
            return;
        }

        setVote(auctionNum);
        setVoteOpen(false);
        setVotedOpen(true);
        setIsVoting(false);
    }

    const handleVoteClick = async(auctionNum, auctionImage, stateChange) => {
        if (!publicKey) {
            setIsVoting(true);
            setPotentialVoteImage(auctionImage);
            setVoteChoice(auctionNum);
            setVisible(true);
            console.log('no pub key');
            return;
        }

        if (stateChange) {
            console.log('state change', stateChange);
            stateChange(false);
            setPotentialVoteImage(auctionImage);
            setPotentialVote(auctionNum);
            setVoteOpen(true);
        }

        if (isVoting) {
            console.log('open vote modal');
            setPotentialVoteImage(auctionImage);
            setPotentialVote(auctionNum);
            setVoteOpen(true);
        }
    }

    return (
            <Box>
        

            <Container align="center" className="vote-container" style={{  }}>
            <Box className="vote-booger vote-booger-600px">
                    <img src={booger1Mobile} className={"booger-mobile"} height="200" />
            </Box>
            <Modal open={voteOpen} onClose={handleVoteClose}>
                <Box> 
                <Stack sx={{ ...modalStyle }} justifyContent="center" alignItems="center">
                    <Box sx={{ textAlign: 'center', position: 'relative', overflow: 'hidden',}}>
                        <h3 style={{ fontSize: '40px', marginBottom: '16px' }}>Cast your vote and <span style={{ color: '#F8630E' }}>claim $creck!!!!</span></h3>
                        <Stack direction="row" justifyContent="center">
                        <Box style={{ overflow: 'hidden', borderRadius: '20px', width: '350px', height: '350px' }}>
                            <img src={potentialVoteImage} width="350" height="350" />
                        </Box>
                        </Stack>
                        
                        <SubmitVoteButton onClick={() => submitVote(potentialVote)} style={{ marginTop: '16px' }}>{ votedOpen ? 'Processing' : 'Submit vote & claim $creck!!!' }</SubmitVoteButton>

                        <Box align="left" mt={2}>
                            <Typography style={{ fontSize: '14px', fontFamily: 'Space Grotesk', fontWeight: 700 }}><Typography style={{ display: 'inline', textDecoration: 'underline', fontSize: '14px', fontFamily: 'Space Grotesk', fontWeight: 700, color: "red" }}>DISCLAIMER:</Typography> Only one wallet can be connected to CAST & CLAIM. This can't be modified and is only a one-time thing.
                            Make sure you have some SOL for transaction fee.</Typography>
                        </Box>
                    </Box>
                </Stack>

                <StyledButton variant="text" sx={{ position: 'absolute', right: 60, top: 40 }}>
                        <img src={closeButton} onClick={handleVoteClose} width="68" />
                    </StyledButton>
                </Box>
                
            </Modal>

            <Modal open={votedOpen} onClose={handleVotedClose}>
                <Box>
            <Stack sx={{ ...modalStyle }} justifyContent="flex-start" alignItems="center" direction="row">
                <Box>
                    <Stack>
                        <Stack alignItems="center" direction="row" mb={3}>
                            <img src={blueCheckLarge} width="64" />
                            <h3 style={{ fontSize: '40px', marginLeft: '24px' }}>Vote casted!</h3>
                        </Stack>

                        <Stack alignItems="center" direction="row" mb={3}>
                            <img src={blueCheckLarge} width="64" />
                            <h3 style={{ fontSize: '40px', marginLeft: '24px' }}>$Creck claimed!</h3>
                        </Stack>

                        <Box style={{ border: '3px dashed #A2BF32', width: '100%'}} />

                        <Stack justifyContent="center" alignItems="center" mt={3}>
                            <h4 style={{ fontSize: '24px', margin: 0, color: '#A2BF32' }}>There is a surprise for you!</h4>
                            <h4 style={{ fontSize: '24px', margin: 0, color: '#A2BF32' }}>Very soon!</h4>

                        </Stack>
                    </Stack>
                </Box>
            </Stack>

                <StyledButton variant="text" sx={{ position: 'absolute', right: 60, top: 40 }}>
                    <img src={closeButton} onClick={handleVotedClose} width="68" />
                </StyledButton>
                </Box>
            </Modal>

            <Modal open={errorOpen} onClose={handleErrorClose}>
                <Box>
            <Stack sx={{ ...modalStyle }} justifyContent="flex-start" alignItems="center" direction="row">
                <Box>
                    <Stack>
                        <Stack alignItems="center" direction="row">
                            <img src={xIconLarge} width="64" />
                            <h3 style={{ fontSize: '40px', marginLeft: '24px' }}>Something went wrong!</h3>
                        </Stack>
                    </Stack>
                </Box>
                </Stack>
                <StyledButton variant="text" sx={{ position: 'absolute', right: 60, top: 40 }}>
                    <img src={closeButton} onClick={handleErrorClose} width="68" />
                </StyledButton>
                </Box>
            </Modal>

            <Stack direction="row" style={{ marginLeft: 'auto' }} alignItems="center" justifyContent="flex-end">
                <Box className="total-votes-box">
                    <Typography color="#DA884E">
                        TOTAL VOTES: {numVotes}
                    </Typography>
                </Box>
            </Stack>

            {/* <Box className="auction-button-container">
                <Box className={`auction-button `} className={showVote1 ? 'auction1-hover' : 'auction1-button'} style={{ width:"350px", height:"350px"}} 
                    onMouseEnter={() => setShowVote1(true)}
                    onMouseLeave={() => setShowVote1(false)}>
                    {showVote1 && 
                        <VoteBox auctionNum="1" imageState={auction1} stateChange={setShowVote1} />
                    } 
                </Box>
            </Box> */}


            <Stack direction="row" className="mobile-vote-header-container">
                <Box className="mobile-dont-forget-box">
                    <img src={dontForget} />
                </Box>

                <Box className="mobile-vote-select">
                    <img src={mobileSelectAuction} />
                </Box>

                <Box className="loser-box" justifyContent="center">
                    <img src={loser} width="275" />
                    <Box className="vote-text-box">
                        <VoteText 
                            component="h1"
                        >VOTE!</VoteText>
                    </Box>
                    {!authenticated && <Box className="mobile-vote-qualify" mt={2} sx={{ border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em' }}>
                        <Typography>
                            CONNECT TWITTER TO CHECK IF YOU CAN VOTE!
                        </Typography>
                    </Box>}

                    {(authenticated && !canVote) && 
                    <Stack direction="row" justifyContent="center" mt={2} sx={{ maxWidth: '70%', border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em', backgroundColor: '#000000' }}>
                        <img src={xIcon} width="23" />
                        <Typography pl={1} color="#E7A1A1">
                            NOT QUALIFIED TO VOTE!
                        </Typography>
                    </Stack>}

                    {(authenticated && canVote && !vote) && 
                    <Stack direction="row" justifyContent="center" mt={2} sx={{ maxWidth: '70%', border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em', backgroundColor: '#000000' }}>
                        <img src={blueCheck} width="23" />
                        <Typography pl={1} color="#B5C6FF">
                            QUALIFIED TO VOTE!
                        </Typography>
                    </Stack>}

                    {(authenticated && canVote && vote) && 
                    <Stack direction="row" justifyContent="center" mt={2} sx={{ maxWidth: '70%', border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em', backgroundColor: '#000000' }}>
                        <img src={blueCheck} width="23" />
                        <Typography pl={1} color="#B5C6FF">
                            YOU ALREADY VOTED!
                        </Typography>
                    </Stack>}
                </Box> 

                
            </Stack>


            <Stack justifyContent="center" direction="row" className="vote-header-container">
            <Box className="vote-booger">
                    <img src={booger1} className={"booger1"} height="200" />
                    <img src={booger1Mobile} className={"booger-mobile"} height="200" />
            </Box>
                <Box mt={0} className="vote-select">
                    <img src={selectAuction} width="288" />
                </Box>
                <Box mt={6} className="loser-box">
                    <img src={loser} width="275" />
                    <Box className="vote-text-box">
                        <VoteText 
                            component="h1"
                        >VOTE!</VoteText>
                    </Box>
                    {!authenticated && <Box mt={2} sx={{ border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em' }}>
                        <Typography>
                            CONNECT TWITTER TO CHECK IF YOU CAN VOTE!
                        </Typography>
                    </Box>}

                    {(authenticated && !canVote) && 
                    <Stack direction="row" justifyContent="center" mt={2} sx={{ border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em', backgroundColor: '#000000' }}>
                        <img src={xIcon} width="23" />
                        <Typography pl={1} color="#E7A1A1">
                            NOT QUALIFIED TO VOTE!
                        </Typography>
                    </Stack>}

                    {(authenticated && canVote && !vote) && 
                    <Stack direction="row" justifyContent="center" mt={2} sx={{ border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em', backgroundColor: '#000000' }}>
                        <img src={blueCheck} width="23" />
                        <Typography pl={1} color="#B5C6FF">
                            QUALIFIED TO VOTE!
                        </Typography>
                    </Stack>}

                    {(authenticated && canVote && vote) && 
                    <Stack direction="row" justifyContent="center" mt={2} sx={{ border: '3px solid #000000', borderRadius: '20px', padding: '0.25em 0.875em', backgroundColor: '#000000' }}>
                        <img src={blueCheck} width="23" />
                        <Typography pl={1} color="#B5C6FF">
                            YOU ALREADY VOTED!
                        </Typography>
                    </Stack>}
                </Box>

                <Box className="dont-forget-box">
                    <img src={dontForget} width="288" />
                </Box>
            </Stack>

            <Grid container spacing={6} mt={8} mb={16} className="auctions-container">
                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className={"auction1-box auction-box-div"}>
                        <Box className="auction-button-container auction1-container">
                            <Box className={`auction-button ${showVote1 ? 'auction1-hover' : 'auction1-button'}`}
                                onMouseEnter={() => { if (canVote) setShowVote1(true) }}
                                onMouseLeave={() => { if (canVote) setShowVote1(false) }}>
                                {showVote1 &&
                                    <VoteBox auctionNum="1" imageState={auction1} stateChange={setShowVote1} />
                                 } 
                            </Box>
                        </Box>
                        {/* <AuctionBox hoverImage={auction1hover} imageState={image1} auctionNum="1" containerStyle={{ transform: 'rotate(-11.31deg)', position: 'relative' }} /> */}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className={"auction2-box auction-box-div"}>
                        <Box className="auction-button-container auction2-container">
                            <Box className={`auction-button ${showVote2 ? 'auction2-hover' : 'auction2-button'}`} 
                                onMouseEnter={() => { if (canVote) setShowVote2(true) }}
                                onMouseLeave={() => { if (canVote) setShowVote2(false) }}>
                                {showVote2 && 
                                    <VoteBox auctionNum="2" imageState={auction2} stateChange={setShowVote2} />
                                 } 
                            </Box>
                        </Box>

                        {/* <AuctionBox hoverImage={auction2hover} imageState={image2} auctionNum="2" containerStyle={{ transform: 'rotate(-9.28deg)', position: 'relative' }} /> */}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className={"auction3-box auction-box-div"}>
                    <Box className="auction-button-container auction3-container">
                            <Box className={`auction-button ${showVote3 ? 'auction3-hover' : 'auction3-button'}`} 
                                onMouseEnter={() => { if (canVote) setShowVote3(true) }}
                                onMouseLeave={() => { if (canVote) setShowVote3(false) }}>
                                {showVote3 && 
                                    <VoteBox auctionNum="3" imageState={auction3} stateChange={setShowVote3} />
                                 } 
                            </Box>
                        </Box>
                        {/* <AuctionBox hoverImage={auction3hover} imageState={image3} auctionNum="3" containerStyle={{ position: 'relative' }} /> */}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className={"auction4-box auction-box-div"}>
                        <Box className="auction-button-container auction4-container">
                            <Box className={`auction-button ${showVote4 ? 'auction4-hover' : 'auction4-button'}`}
                                onMouseEnter={() => { if (canVote) setShowVote4(true) }}
                                onMouseLeave={() => { if (canVote) setShowVote4(false) }}>
                                {showVote4 && 
                                    <VoteBox auctionNum="4" imageState={auction4} stateChange={setShowVote4} />
                                 } 
                            </Box>
                        </Box>
                    </Box>
                        {/* <AuctionBox hoverImage={auction4hover} imageState={image4} auctionNum="4" containerStyle={{ position: 'relative' }} /> */}
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className={"auction5-box auction-box-div"}>
                        <Box className="auction-button-container auction5-container">
                            <Box className={`auction-button ${showVote5 ? 'auction5-hover' : 'auction5-button'}`} 
                                onMouseEnter={() => { if (canVote) setShowVote5(true) }}
                                onMouseLeave={() => { if (canVote) setShowVote5(false) }}>
                                {showVote5 && 
                                    <VoteBox auctionNum="5" imageState={auction5} stateChange={setShowVote5} />
                                 } 
                            </Box>
                        </Box>
                        {/* <AuctionBox hoverImage={auction5hover} imageState={image5} auctionNum="5" containerStyle={{ transform: 'rotate(7.68deg)', position: 'relative' }} /> */}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className={"auction6-box auction-box-div"}>
                        <Box className="auction-button-container auction6-container">
                            <Box className={`auction-button ${showVote6 ? 'auction6-hover' : 'auction6-button'}`}
                                onMouseEnter={() => { if (canVote) setShowVote6(true) }}
                                onMouseLeave={() => { if (canVote) setShowVote6(false) }}>
                                {showVote6 && 
                                    <VoteBox auctionNum="6" imageState={auction6} stateChange={setShowVote6} />
                                 } 
                            </Box>
                        </Box>

                        {/* <AuctionBox hoverImage={auction6hover} imageState={image6} auctionNum="6" containerStyle={{ transform: 'rotate(-19.27deg)', position: 'relative' }} /> */}
                    </Box>
                </Grid>

                <Grid item xs={4}>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className={"auction7-box auction-box-div"}>
                        <Box className="auction-button-container auction7-container">
                            <Box className={`auction-button ${showVote7 ? 'auction7-hover' : 'auction7-button'}`}
                                onMouseEnter={() => { if (canVote) setShowVote7(true) }}
                                onMouseLeave={() => { if (canVote) setShowVote7(false) }}>
                                {showVote7 && 
                                    <VoteBox auctionNum="7" imageState={auction7} stateChange={setShowVote7} />
                                 } 
                            </Box>
                        </Box>

                        {/* <AuctionBox hoverImage={auction7hover} imageState={image7} auctionNum="7" containerStyle={{ transform: 'rotate(-6.83deg)', position: 'relative' }} /> */}
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={4} className={"auction-div"} sx={{ position: 'relative' }}>
                    <Box className="vote-character-box" >
                            <img src={booger2} width="175" />

                            <Box className="wtf-happening">
                            <img src={wtfHappening} width="175" style={{ position: 'relative', top: '10px' }} />
                    </Box>
                    </Box>
                </Grid>
            </Grid>

        </Container>
       
        </Box>
    )
}

export default Vote;