import React, { useEffect, useState, useSearchParams } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Masonry from '@mui/lab/Masonry';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import MemeTweet from '../components/MemeTweet';
import client from '../socket/client';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import Loader from '../components/Loader';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import feedCharacter from '../assets/coc-img-horiz.png';
// import memeFeed from '../assets/meme-feed.png';
import postMemes from '../assets/post-memes.png';
import postMemesMobile from '../assets/post-memes-mobile.png';
import pressButton from '../assets/press-button.png';
import insertHere from '../assets/insert-here.png';
import chokeChicken from '../assets/choke-chicken.png';
import chokeArrow from '../assets/choke-arrow.png';
import fatStare from '../assets/fat-stare.png';
import chokeButton from '../assets/choke-button.png';
import cocTears from '../assets/coc-tears.png';
import cocCream from '../assets/cream-coc.png';

import cocFeedVector from '../assets/coc-feed-vector.png';

import memesBanner from '../assets/memes-divider.png';
import firstMemesBanner from '../assets/memes-divider.png';
import submitButton from '../assets/white-arrow.png';
import checkMark from '../assets/checkmark.png';
import xIconLarge from '../assets/x-icon-large.png';
import closeButton from '../assets/close-button.png';
import progressLock from '../assets/progress-lock.png';
import progressReveal from '../assets/progress-reveal.png';
import loader from '../assets/loader.png';
import TwitterIcon from '@mui/icons-material/Twitter';
import MemeImage from '../components/MemeImage';
import "../assets/styles/progress-bar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import nFormatter from '../helpers/numberFormatter';
import cocTwitter from '../assets/coc-twitter.png';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: '50%' },
    bgcolor: '#009251',
    borderRadius: 4,
    paddingX: '60px',
    paddingY: '40px',
    border: '2px solid #000000',
    boxShadow: '4px 4px #000',
  };


  const ConnectButton = styled(Button)({
    backgroundColor: '#FFF',
    borderRadius: 20,
    color: '#000',
    border: '2px solid #000',
    boxShadow: '2px 4px #000',
    '&:hover': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
      '&:disabled': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
});

const CloseButton = styled(Button)({
    backgroundColor: '#FFF',
    borderRadius: 20,
    color: '#000',
    border: '2px solid #000',
    boxShadow: '2px 4px #000',
    '&:hover': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
      '&:disabled': {
        backgroundColor: '#FFF',
        boxShadow: '2px 4px #000',
      },
});

  const MEMES_PER_PAGE = 500;

  
function Feed(props) {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(true);
    const [collapseTimeout, setCollapseTimeout] = useState(300);
    const [memeUrl, setMemeUrl] = useState('');
    const [error, setError] = useState('Something went wrong!');
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [loaded, setLoaded] = useState(0);
    const [doneLoading, setDoneLoading] = useState(false);
    const [memeCountText, setMemeCountText] = useState('');
    const [numForReveal, setNumForReveal] = useState(100);
    const [progress, setProgress] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);

    const [loading, setLoading] = useState(true);
    const [showRefresh, setShowRefresh] = useState(false);

    const [submitOpen, setSubmitOpen] = useState(false);
    const handleSubmitClose = () => setSubmitOpen(false);

    const [connectOpen, setConnectOpen] = useState(false);
    const handleConnectClose = () => setConnectOpen(false);

    const [errorOpen, setErrorOpen] = useState(false);
    const handleErrorClose = () => setErrorOpen(false);

    const vertical = 'top';
    const horizontal = 'center';

    const handleTwitterConnect = () => {
        const loginUrl = `${process.env.REACT_APP_MEME_API_URL}/auth/twitter?redirect=${location.pathname}&memeUrl=${memeUrl}`;
        window.open(loginUrl, "_self");
    }

    const urlChange = (event) => {
        setMemeUrl(event.target.value);
    }

    const submitMeme = async() => {
        // console.log(authenticated);
        if (!authenticated) {
            setConnectOpen(true);
        } else {
            const url = `${process.env.REACT_APP_MEME_API_URL}/meme`;
            const options = {
                method: 'POST',
                body: JSON.stringify({ url: memeUrl }),
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` },
            };
            const resp = await fetch(url, options);
            const jsonResp = await resp.json();

            if (jsonResp.error) {
                setError(jsonResp.error);
                setErrorOpen(true);
            } else {
                setSubmitOpen(true);
                setMemeUrl('');
            }
            // console.log(jsonResp);
            // console.log(memeUrl);
        }
    }
    
    const StyledButton = styled(Button)({
        '&:hover': {
            transitionProperty: 'scale',
            transitionDuration: '0.5s',
            scale: '1.2',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
    });

    const SearchButton = () => (
        <StyledButton >
        {/* <Box sx={{ position: 'absolute', zIndex: 1, paddingTop: 1, paddingLeft: 1, right: 0 }}> */}
                {/* <img src={chokeButton} width={40} /> */}
            {/* </Box> */}
            {/* <Box sx={{ zIndex: 2, position: 'absolute', paddingBottom: 2, paddingLeft: 2, right: 0 }} > */}
                <img src={fatStare}  className="search-btn" />
            {/* </Box>            */}
        </StyledButton>
        )
    
    
    const addMeme = (tweetId) => {
        setOpen(false);
        setCollapseTimeout(0);
        // console.log(items);
        setItems([ 
            <MemeTweet tweetId={tweetId} key={tweetId} />,
            ...items, 
    ]);
    setTimeout(() => {
        setCollapseTimeout(300);
        setOpen(true);
      }, 1000);
    };

    const handleScroll = () => {
        if (
            Math.ceil(window.innerHeight + document.documentElement.scrollTop) <=
              document.documentElement.offsetHeight ||
            isFetching
          ) {
              return;
          }
        
        setIsFetching(true);
    };
    
    const fetchMemes = async() => {
        const url = `${process.env.REACT_APP_MEME_API_URL}/latest?page=${page}&limit=${MEMES_PER_PAGE}`;
        const resp = await fetch(url);
        const jsonResp = await resp.json();
        setPage(page + 1);

        if (jsonResp.length < MEMES_PER_PAGE) {
            setDoneLoading(true);
        }

        const memes = jsonResp.map(meme => {
            if (meme.tweetImgUrl.length > 0) {
                // setLoaded(loaded + 1);
                // console.log(meme.tweetImgUrl);
                return <MemeImage key={meme.id} memeImg={meme.tweetImgUrl} memeUrl={meme.url} />
            }

            // return <TwitterTweetEmbed
            //     onLoad={function noRefCheck(){ setLoaded(loaded + 1 )}}
            //     // onLoad={}
            //     options={{ theme: "dark", width: "400", dnt: true, conversation: 'none' }} 
            //     placeholder={<Loader />}
            //     tweetId={meme.tweetId}
            //     key={meme.id}
            // />

            // return <MemeTweet tweetId={meme.tweetId} key={meme.id} useQueryArrived={useQueryArrived} />;
        });

        if (memes.length === 0) {
            setLoaded(1);
            // console.log('no memes way');
        }

        // console.log(jsonResp);

        setItems(() => {
            return [...items, ...memes];
        });
    }

    useEffect(() => {
        async function handleAuth() {
        // console.log(authenticated);        
        // console.log(props.authenticated);

        setAuthenticated(props.authenticated);
        if (authenticated) {
            const params = new URLSearchParams(location.search);
            const returnMemeUrl = params.get('memeUrl');
            if (returnMemeUrl) {
                setMemeUrl(returnMemeUrl);
                await submitMeme();
                // history.replace({ pathname: location.pathname, search: '' });
            }
        }
        }

        handleAuth();
    }, [props]);

    useEffect(() => {
        if (loaded === items.length) {
            setLoading(false);
        }
    }, [loaded])

    useEffect(() => {
        fetchMemes();

        window.addEventListener('scroll', handleScroll);

        async function fetchMemeCount() {
            const url = `${process.env.REACT_APP_MEME_API_URL}/approved-count`;
            const resp = await fetch(url);
            const jsonResp = await resp.json();
            setMemeCountText(nFormatter(jsonResp.count) + ` memes`);

            setProgress(((jsonResp.count + 1) / (jsonResp.total + 100)) * 100);
        }

        fetchMemeCount();

    }, []);

    useEffect( () => {
        client.onopen = () => {
            console.log('ws client connected');
        };

        client.onmessage = (msg) => {
            const message = JSON.parse(msg.data);
            // console.log(message);
            if (message && message.action === 'latestMemes' && !showRefresh) {
                // const memes = message.data;
                // for (let i = 0; i < message.data.length; i++) {
                //     addMeme(message.data[i].tweetId);                    
                // }
                setShowRefresh(true);
            }
        };
     }, [client]);

    //  const memes = items.map((it, i) => {
    //     if (i > 0) {
    //         // console.log('non-wrapped');
    //         return it;
    //     }
    //     // console.log('wrapped');
    //     return (
    //         <Collapse in={open} timeout={collapseTimeout}>
    //             <Box>
    //                 {it}
    //             </Box>
    //         </Collapse>
    //     )
    //  });

     useEffect(() => {
        if (!isFetching || doneLoading) return;
        fetchMoreMemes();
      }, [isFetching]);

      const fetchMoreMemes = () => {
        fetchMemes();
        setIsFetching(false);
      };

      const handleRefreshClick = () => {
          setShowRefresh(false);
          navigate(0);
      }

      const refreshAction = (
        <Button onClick={handleRefreshClick} variant="contained" color="inherit" size="small" style={{ borderRadius: '20px', backgroundColor: '#000000', color: '#FFFFFF' }}>
            REFRESH
        </Button>
      );
    return (
        <>
            {/* <div className={loading ? "loading" : "loaded"}>
                    <Stack className="loader-container" justifyContent="center" alignItems="center">
                        <img src={loader} width="352" />
                    </Stack>
            </div> */}

        <Container align="center" className="feed-container coc-feed-vector">
        <Snackbar
                open={showRefresh}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
                autoHideDuration={15000}
                onClose={() => setShowRefresh(false)}
                >
            <Alert severity="info" icon={false} action={refreshAction}>New memes!!!</Alert>        
        </Snackbar>

        <Stack justifyContent="center" mt={{ xs: 8, md: 8, lg: 12 }} direction="row">
            <Box className='postMemes' sx={{ position: 'relative', left: 10, top: 60 }}>
                <img src={insertHere} width="300" />
            </Box>
            {/* <Box className='postMemesMobile' sx={{  }}>
                <img src={postMemesMobile}/>
            </Box> */}
            <Box className='revealHeader-div'>
            <Typography className='header3'>MEME FEED</Typography>
                <Box sx={{ position: 'relative'}}>
                    <Box className='search-button'>
                        <SearchButton />
                    </Box>
                     <Box className='choke-button'>
                        <img src={chokeButton} width={40} />
                    </Box>
                    <Box className='TextField-div' mt={6}>
                        <TextField 
                        className='text-field'
                            placeholder="Paste Your Meme Twitter Link"
                            onChange={urlChange}
                            value={memeUrl}
                            variant="standard"
                            disabled={true}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    submitMeme();
                                }
                            }}
                            InputProps={{ style: { fontSize: 18 }, disableUnderline: true }}
                        />
                        {/* {showError && <Typography component="p" mt={2} sx={{ color: 'red' }}>Error! {error}</Typography>} */}
                    </Box>
                    
                   
                </Box>
                
            </Box>

            <Box className="pressButton" sx={{ position: 'relative', top: 130, zIndex: 2 }}>
                <img src={chokeChicken} className="choke-img" width="200" />
            </Box>

            <Box className="pressButton" sx={{ position: 'relative', top: 220, right: 200, zIndex: 2 }}>
                <img src={chokeArrow} width="70" />
            </Box>
        </Stack>

        {/* <Box mt={4} ml={{ sm: 2, md: 1, lg: 5, xl: 20 }}>
            <Typography component={'h4'} fontSize={{ xs: 20, md: 24, lg: 28, xl: 32 }}>MEMES CAN TAKE 24 HOURS TO APPROVE</Typography>
        </Box> */}
        
        <Box mt={4} ml={{ sm: 2, md: 1, lg: 5, xl: 20 }}>
            <Typography component={'h4'} fontSize={{ xs: 20, md: 24, lg: 28, xl: 32 }}>MEME PORTAL IS CLOSED!</Typography>
        </Box>

        <Box my={{ xs: 6, sm: 10, md: 10, lg: 12 }} sx={{ position: 'absolute', right: 0, whiteSpace: 'nowrap' }}>
            <img src={firstMemesBanner} />
            <img className="memes-banner" src={memesBanner} height={44} />
            <img src={memesBanner} height={44} />
            <img src={memesBanner} height={44}  />
            <img src={memesBanner} height={44} />
            <img src={memesBanner} height={44} />
            <img src={memesBanner} height={44} />
            <img src={memesBanner} height={44} />
        </Box>

            {/* <Button onClick={() => addMeme('1592129851225690112')}>Add</Button> */}

            <Modal open={errorOpen} onClose={handleErrorClose}>
                <Box> 

                <Stack className='modal-connect' sx={{ ...modalStyle, paddingBottom: 20 }} justifyContent="center" alignItems="center">
                    <Box sx={{ textAlign: 'center', transform: 'rotate(1deg)' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 30, md: 48 }}>MEME SUBMISSION FAILED</Typography>
                    </Box>
                    
                    <Box sx={{ textAlign: 'center', width: '100%', transform: 'rotate(-2deg)' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 20, md: 30 }}>SUTTIN WENT WRONG. REALLY WRONG.</Typography>
                    </Box>

                    <Box sx={{ display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap' }}>
                        <CloseButton onClick={handleErrorClose} variant="contained" size="large" disableElevation>
                            <Typography component="span">{'close the window'}</Typography>
                            {/* <img src={cocTwitter} width="31" /> */}
                        </CloseButton>
                    </Box>

                <Box sx={{ position: 'absolute', bottom: -5 }}>
                    <img src={cocTears} width={150} />
                </Box>
                <StyledButton variant="text" sx={{ position: 'absolute', right: 6, top: 10 }} onClick={handleErrorClose}>
                    <Typography component={'h4'} fontSize={30}>X</Typography>
                </StyledButton>
                </Stack>
{/* 
                <Stack sx={{ ...modalStyle, paddingX: 5 }} justifyContent="center" alignItems="center">
                    <img src={error.includes('awaiting') ? checkMark : xIconLarge} />
                    <h3 style={{ fontSize: '2em' }}>{ error ? error : 'Invalid Link!' }</h3>
                </Stack> */}

                </Box>
            </Modal>

            <Modal open={submitOpen} onClose={handleSubmitClose}>
                <Box> 
                <Stack className='modal-connect' sx={{ ...modalStyle, paddingBottom: 20 }} justifyContent="center" alignItems="center">
                    <Box sx={{ textAlign: 'center', transform: 'rotate(1deg)' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 30, md: 48 }}>MEME SUBMISSION SUCCESSFUL</Typography>
                    </Box>
                    
                    <Box sx={{ textAlign: 'center', width: '100%', transform: 'rotate(-2deg)' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 20, md: 30 }}>KEEP POSTING MEMES FOR MORE CHANCES TO GAIN $COC</Typography>
                    </Box>

                    <Box sx={{ display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap' }}>
                        <CloseButton onClick={handleSubmitClose} variant="contained" size="large" disableElevation>
                            <Typography component="span">{'ok. gotcha lets make memes'}</Typography>
                            {/* <img src={cocTwitter} width="31" /> */}
                        </CloseButton>
                    </Box>

                <Box sx={{ position: 'absolute', bottom: -6 }}>
                    <img src={cocCream} width={175} />
                </Box>
                <StyledButton variant="text" sx={{ position: 'absolute', right: 6, top: 10 }} onClick={handleSubmitClose}>
                    <Typography component={'h4'} fontSize={30}>X</Typography>
                </StyledButton>
                </Stack>
                </Box>
            </Modal>

            <Modal open={connectOpen} onClose={handleConnectClose}>
            <Box> 
                <Stack className='modal-connect' sx={{ ...modalStyle }} justifyContent="center" alignItems="center">
                    {/* <img src={checkMark} /> */}
                    <Box sx={{ textAlign: 'center', transform: 'rotate(1deg)' }} mb={4}>
                        <Typography component={'h4'} fontSize={{ xs: 30, md: 48 }}>CONNECT YOUR <span style={{ color: '#FFD400' }}>TWITTER</span> TO SUBMIT MEMES.</Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap' }}>
                        <ConnectButton onClick={handleTwitterConnect} variant="contained" size="large" disableElevation>
                            <Typography component="span" pr={2}>{'CONNECT TWITTER'}</Typography>
                            <img src={cocTwitter} width="31" />
                        </ConnectButton>
                    </Box>

                    <StyledButton variant="text" sx={{ position: 'absolute', right: 6, top: 10 }} onClick={handleConnectClose}>
                        <Typography component={'h4'} fontSize={30}>X</Typography>
                    </StyledButton>
                </Stack>

                {/* <StyledButton variant="text" sx={{ position: 'absolute', right: 60, top: 40 }}>
                    <img src={closeButton} onClick={handleSubmitClose} width="68" />
                </StyledButton> */}
                </Box>
            </Modal>
            
            
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
                // onClick={handleClose}
                >
            
                
            </Backdrop> */}

            <Box  mt={{ xs: 17, sm: 25, md: 26 }}>
                <Masonry columns={{ xs: 1, sm: 2, md: 2,lg:3 }}>
                    {items}
                </Masonry>
            </Box>
        </Container>
        
        </>
    )
}

export default Feed;