import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    paddingLeft: 15,
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
    marginBottom: 15,
}));

function ProfileItem(props) {
    const navigate = useNavigate();

    const handleClick = (userId) => {
        console.log('clicked');
        navigate(`/profile/${userId}`);
    }


    return (
        // <Item key="all" elevation="24">
            <Box sx={{ mb: 2 }} onClick={() => handleClick(props.profile.userId)}>
            <Card>
                <CardHeader
                    align="left"
                    avatar={
                    <Badge badgeContent={props.profile.rank} color="primary">
                    <Avatar src={props.profile.profileImg} />
                    </Badge>
                    }
                    title={props.profile.name}
                    subheader={'@' + props.profile.username}
                    action={
                        <div>
                            {props.showRank && <Chip label={props.profile.rank} />}

                            <div>
                                {props.profile.memeCount} {props.profile.memeCount === 1 ? 'meme' : 'memes'}
                            </div>
                            {props.showLastMeme && <div>
                                {moment(props.profile.approvalDate).fromNow()}
                            </div>}
                        </div>
                        }
                >
                </CardHeader>
            </Card>
            </Box> 
        
    )
}

export default ProfileItem;