import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { Link, Route, Routes, NavLink } from "react-router-dom";
import Characters from './pages/Characters';
import Feed from './pages/Feed';
import Leaderboard from './pages/Leaderboard';
import Vote from './pages/Vote';
import Assets from './pages/Assets';
import Claim from './pages/Claim';
import Reveal from './pages/Reveal';
import Profile from './pages/Profile';
import Approve from './pages/Approve';
import TwitterLogin from './components/TwitterLogin';
import cocTwitter from './assets/coc-twitter.png';
import WalletContainer from './components/WalletContainer';
import ProtectedRoute from './components/ProtectedRoute';
import Container from '@mui/material/Container';
import logo from './assets/coc-logo-red.png';
import logoMob from './assets/mobile-navbar.png';
import closeMob from './assets/close-button.png';
import Box from '@mui/material/Box';
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider, WalletDisconnectButton, WalletMultiButton, WalletConnectButton } from "@solana/wallet-adapter-react-ui";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from '@solana/web3.js';

import './assets/styles/wallet-adapter-styles.css';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';

function Web3Provider(props) {
  let network = "https://indulgent-thrilling-feather.solana-devnet.discover.quiknode.pro/9344a967a6e2a9db8c32232e92bd95851dd4c6d3/";
  
  if (process.env.REACT_APP_SOLANA_NETWORK) {
    network = clusterApiUrl(process.env.REACT_APP_SOLANA_NETWORK);
  }

  const wallets = useMemo(
      () => [
        new PhantomWalletAdapter(),
      ],
      [network]
  );


  return (
      <ConnectionProvider endpoint={network}>
          <WalletProvider wallets={wallets} autoConnect={true}>

            <WalletModalProvider>
            {/* <WalletDisconnectButton /> */}
            {/* <WalletMultiButton /> */}
            {props.children}
            </WalletModalProvider>
          </WalletProvider>
      </ConnectionProvider>
    );
}

const StyledButton = styled(Button)({
  '&:hover': {
      transitionProperty: 'scale',
      transitionDuration: '0.5s',
      scale: '1.2',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
});

function AppChild() {
  const location = useLocation();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [twitterUser, setTwitterUser] = useState({});
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  
  const [canApprove, setCanApprove] = useState(false);

  const APPROVAL_USERS = [
    'cets_sales',
    'FimixGG',
    'Gustevo420',
  ];

  useEffect(() => {
    // console.log(`we are changing authentication ${authenticated}`)
    if (authenticated) {
      const jwt = Cookies.get('token');
      
      if (jwt) {
          const decoded = jwt_decode(jwt);
          // console.log(decoded);
          if (decoded.permissions && decoded.permissions.includes('can_approve')) {
              setCanApprove(true);
              return;
          }
      }
    }
  }, [authenticated]);

  const loginStatus = (authStatus) => {
    setAuthenticated(authStatus);
  }

  const signedInUser = (user) => {
    setTwitterUser(user);
  }
  let bodyClass = () => {
    
    if (location.pathname === '/vote') {
      return 'laboog-art';
    }
    // if (location.pathname === '/feeds') {
    //   return 'laboog-art';
    // }
    if (location.pathname.includes('/reveal')) {
      return 'reveal-page';
    }
  }

  return (
    <div>
    <div className="generic-bg"></div>
      <Container disableGutters={true} className={bodyClass()} fluid="true" maxWidth={false}> 
        <Box sx={ location.pathname === '/blah' ? { backgroundColor: '#ccd0d066'} : {}}>
          <Box className="logo-container" onClick={() => navigate("/")}>
            <img src={logo} width="100" />
            {/* <a href="https://twitter.com/boogerswtf"><img src={followTwitter} className="logo-followTwitter" /></a> */}
            <Box display="flex" justifyContent="center" alignItems="center" onClick={() => window.open("https://twitter.com/cocthecoin", "_blank")}>
            <Typography pr={2} className="follow-text">FOLLOW TWITTER</Typography>
            <a href="https://twitter.com/cocthecoin"><img src={cocTwitter} className="logo-followTwitter" /></a>

            </Box>
          </Box>
          <div className="menu" >
              <ul id="topNav">
                <li> <NavLink to="/feeds" activeClassName="active" state={{ authenticated: authenticated }}>FEED</NavLink> </li>
                <li> 
                  <NavLink to="/leaderboard" activeClassName="active">
                    MEMERBOARD
                  </NavLink> </li>
                  <li> <NavLink to="/" >MEME ASSETS</NavLink> </li>

                {/* <li> <NavLink to="/vote" activeClassName="active" state={{ authenticated: authenticated }}>VOTE</NavLink> </li> */}
                <li> <NavLink to="/claim" activeClassName="active" disabled>CLAIM</NavLink> </li>
                { twitterUser && twitterUser.username && canApprove && 
                  <li> <NavLink to="/approval" activeClassName="active">APPROVE</NavLink> </li> 
                  }
                <li> <TwitterLogin loginStatus={loginStatus} signedInUser={signedInUser} returnPath={location.pathname} /></li>
                {/* <li className="wallet"> <WalletContainer /> </li> */}
              </ul>
          </div>
          <div className={"mobile-nav"}>
          <Box className="logo-container-mobile" onClick={() => navigate("/")}>
            <img src={logo} width="100" />
          </Box>
          <div className='logo-mobile'>
            <TwitterLogin isMobile={true}loginStatus={loginStatus} signedInUser={signedInUser} returnPath={location.pathname} />
            <img src={logoMob} className={'logo-mobile-nav'} onClick={()=>setNavbarOpen(true)} width="53" />
            
            </div>
            </div>
            <div className={`${navbarOpen?"logo-mobile-full":"logo-mobile-close"} `}>
            {/* <img src={closeMob} className={'logo-mobile-nav-close'} onClick={()=>setNavbarOpen(false)} width="53" />
             */}
             <StyledButton variant="text" sx={{ position: 'absolute', right: 6, top: 10 }} onClick={()=>setNavbarOpen(false)} >
                        <Typography component={'h4'} fontSize={30}>X</Typography>
                    </StyledButton>
            <div>
            <ul className="mobile-div">
                <li> <NavLink to="/feeds" onClick={()=>{setNavbarOpen(false)}} activeClassName="active" state={{ authenticated: authenticated }}>FEED</NavLink> </li>
                <li> 
                  <NavLink to="/leaderboard" onClick={()=>{setNavbarOpen(false)}} activeClassName="active">
                    MEMERBOARD
                  </NavLink> </li>
                <li> <NavLink to="/" onClick={()=>{setNavbarOpen(false)}}>MEME ASSETS</NavLink> </li>

                <li> <NavLink to="/claim" onClick={()=>{setNavbarOpen(false)}} activeClassName="active" state={{ authenticated: authenticated }}>CLAIM</NavLink> </li>
                {/* <li> <NavLink to="/reveal"onClick={()=>{setNavbarOpen(false)}} activeClassName="active">ART REVEAL</NavLink> </li> */}
                { twitterUser && twitterUser.username && canApprove && 
                  <li> <NavLink to="/approval"onClick={()=>{setNavbarOpen(false)}} activeClassName="active">Approve</NavLink> </li> 
                  }
                {/* <li className="wallet"> <WalletContainer /> </li> */}
              </ul>
                  <div className='mobile-navbar'>
              <TwitterLogin loginStatus={loginStatus} signedInUser={signedInUser} returnPath={location.pathname} />
              </div>
            </div>
            </div>
        </Box>

      
      </Container>

   
      <Routes>
          <Route path="/" element={<Assets />}/>
          <Route exact path="/feeds" element={<Feed authenticated={authenticated} />}/>
          <Route mt={5} exact path="/leaderboard" element={<Leaderboard />}/>
          <Route exact path="/vote" element={<Vote authenticated={authenticated} />}/>
          <Route exact path="/claim" element={<Claim authenticated={authenticated} />}/>
          <Route path="/reveal/:revealNum" element={<Reveal />}/>
          <Route exact path="/approval" element={
            <ProtectedRoute user={canApprove}>
              <Approve />
            </ProtectedRoute>
            }/>
          <Route exact path="/profile/:username" element={<Profile />} />
      </Routes>
            
    </div>
  );
}

function App() {
  return (
    <Web3Provider>
      <AppChild />
    </Web3Provider>
  );
}

export default App;
