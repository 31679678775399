import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { TwitterTweetEmbed } from 'react-twitter-embed';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Masonry from '@mui/lab/Masonry';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import profileHeader from '../assets/profile-header.png';
import profileDecoration from '../assets/profile-decoration.png';
import memesBanner from '../assets/memes-divider.png';
import firstMemesBanner from '../assets/memes-divider.png';
import { NavLink } from "react-router-dom";
import MemeImage from '../components/MemeImage';
import levelBadge from '../assets/level-badge.png';
import levelBlob from '../assets/level-blob.png';
import babyCoc from '../assets/baby-coc.png';
import normieCoc from '../assets/normie-coc.png';
import midCoc from '../assets/mid-coc.png';
import chadCoc from '../assets/chad-coc.png';
import monsterCoc from '../assets/monster-coc.png';
import chickenShit from '../assets/chicken-shit.png';
import bar from '../assets/bar.png';

const BABY_COC_LEVEL = 1;
const NORMIE_COC_LEVEL = 3;
const MID_COC_LEVEL = 10;
const CHAD_COC_LEVEL = 20;
const MONSTER_COC_LEVEL = 35;
const MEMES_PER_PAGE = 100;

const colors = ['blue', 'red', 'yellow', 'orange', 'green'];

function ProfileBox(props) {
    let { profile, items } = props;
    let [colorClass, setColorClass] = useState(colors[Math.floor(Math.random()*colors.length)]);

    let profileImg = profile.profileImg ? profile.profileImg.replace('_normal.', '.') : '';
    
    return (
        <>
        <Box width={{ xs: '100%', sm: '85%' }} display={{ xs: 'block', sm: "flex" }} justifyContent="flex-start" mt={8}>
            {/* <Stack className={'profile-box ' + colorClass}  justifyContent="center" alignItems="center" direction="row">
                <img src={profileHeader} width="333" />
            </Stack> */}
            <Box className="profile-container" >
                <Box className="profile-img-container" display="flex" alignItems="center">
                    <img src={profileImg} width="100" style={{ borderRadius: '20px' }} />

                    <Box className="profile-user-container" ml={4}>
                    <Typography fontSize={{ xs: 16, sm: 36 }} lineHeight={1}>
                        {profile.name}
                    </Typography>
                    <Typography textAlign="left" fontSize={{ xs: 14, sm: 24 }} color="#5FBF7E" lineHeight={1}>
                        @{profile.username}
                    </Typography>
                    </Box>
                </Box>

                <Box className="profile-memes-container" mr={2}>
                    <Typography textAlign="center" fontSize={{ xs: 16, sm: 36 }} lineHeight={1}>
                        {items.length}
                    </Typography>

                    <Typography textAlign="center" fontSize={{ xs: 14, sm: 24 }} color="#5FBF7E" lineHeight={1}>
                        MEMES
                    </Typography>
                </Box>
            </Box>

            {/* <Stack className="mobile-profile-container">
                <Box className="profile-img-container" display="flex" alignItems="center">
                    <img src={profileImg} width="100" style={{ borderRadius: '20px' }} />

                    <Box className="profile-user-container" ml={4}>
                    <Typography fontSize={36} lineHeight={1}>
                        {profile.name}
                    </Typography>
                    <Typography textAlign="left" fontSize={24} color="#5FBF7E" lineHeight={1}>
                        @{profile.username}
                    </Typography>
                    </Box>
                </Box>

                <Box className="profile-memes-container" mr={2}>
                    <Typography textAlign="center" fontSize={36} lineHeight={1}>
                        {items.length}
                    </Typography>

                    <Typography textAlign="center" fontSize={24} color="#5FBF7E" lineHeight={1}>
                        MEMES
                    </Typography>
                </Box>
            </Stack> */}

            <Box className="level-container" ml={4} display="flex" alignItems="center">
                <Box pl={1}>
                    <img src={levelBlob} width={120} />
                </Box>
                {/* <Box position="absolute" display="flex" alignItems="center" justifyContent="center" width={130}>
                    <img src={levelBadge} width={110} />
                </Box> */}

                <Box position="absolute" display="flex" alignItems="center" justifyContent="center" width={130}>
                    {items.length >= BABY_COC_LEVEL && items.length < NORMIE_COC_LEVEL && <img src={babyCoc} width={110} />}
                    {items.length >= NORMIE_COC_LEVEL && items.length < MID_COC_LEVEL && <img src={normieCoc} width={110} />}
                    {items.length >= MID_COC_LEVEL && items.length < CHAD_COC_LEVEL && <img src={midCoc} width={110} />}
                    {items.length >= CHAD_COC_LEVEL && items.length < MONSTER_COC_LEVEL && <img src={chadCoc} width={110} />}
                    {items.length >= MONSTER_COC_LEVEL && <img src={monsterCoc} width={70} />}
                </Box>
            </Box>
            <Box display="flex" alignItems="center" ml={{ xs: 0, sm: 2 }} justifyContent={{ xs: "center", sm: "flex-start" }} textAlign="left">
                <Stack >
                    <Box>
                        <Typography className="level-text">
                            {items.length >= BABY_COC_LEVEL && items.length < NORMIE_COC_LEVEL && <span>LEVEL 1</span>}
                            {items.length >= NORMIE_COC_LEVEL && items.length < MID_COC_LEVEL && <span>LEVEL 2</span>}
                            {items.length >= MID_COC_LEVEL && items.length < CHAD_COC_LEVEL && <span>LEVEL 3</span>}
                            {items.length >= CHAD_COC_LEVEL && items.length < MONSTER_COC_LEVEL && <span>LEVEL 4</span>}
                            {items.length >= MONSTER_COC_LEVEL && <span>LEVEL 5</span>}
                        </Typography>
                    </Box>

                    <Box>
                    <Typography className="level-name-text">
                        {items.length >= BABY_COC_LEVEL && items.length < NORMIE_COC_LEVEL && <span>BABY COC</span>}
                        {items.length >= NORMIE_COC_LEVEL && items.length < MID_COC_LEVEL && <span>NORMIE COC</span>}
                        {items.length >= MID_COC_LEVEL && items.length < CHAD_COC_LEVEL && <span>MID-CURVE COC</span>}
                        {items.length >= CHAD_COC_LEVEL && items.length < MONSTER_COC_LEVEL && <span>CHAD COC</span>}
                        {items.length >= MONSTER_COC_LEVEL && <span>MONSTER COC</span>}
                    </Typography>
                    </Box>
                </Stack>
            </Box>


        </Box>
        <Box width={{ xs: '90%', sm: '85%' }} display="flex" justifyContent="flex-start" mt={{ xs: 4, sm: 8 }}>
            <Stack width={"100%"} position="relative">

            <Typography className="level-name-text" textAlign="left">
                {items.length >= BABY_COC_LEVEL && items.length < NORMIE_COC_LEVEL && <span>LEVEL 1</span>}
                {items.length >= NORMIE_COC_LEVEL && items.length < MID_COC_LEVEL && <span>LEVEL 2</span>}
                {items.length >= MID_COC_LEVEL && items.length < CHAD_COC_LEVEL && <span>LEVEL 3</span>}
                {items.length >= CHAD_COC_LEVEL && items.length < MONSTER_COC_LEVEL && <span>LEVEL 4</span>}
                {items.length >= MONSTER_COC_LEVEL && <span>LEVEL 5</span>}
            </Typography>
            <Box className="level-box" width={"100%"}>
                <Box className="level-bar-container">
                    <img src={bar} />
                </Box>

                {items.length >= BABY_COC_LEVEL && items.length < NORMIE_COC_LEVEL && <Box className="level-empty" sx={{ width: '80%' }}></Box>}
                {items.length >= NORMIE_COC_LEVEL && items.length < MID_COC_LEVEL && <Box className="level-empty" sx={{ width: '60%' }}></Box>}
                {items.length >= MID_COC_LEVEL && items.length < CHAD_COC_LEVEL && <Box className="level-empty" sx={{ width: '40%' }}></Box>}
                {items.length >= CHAD_COC_LEVEL && items.length < MONSTER_COC_LEVEL && <Box className="level-empty" sx={{ width: '20%' }}></Box>}
                {items.length >= MONSTER_COC_LEVEL && <Box className="level-empty" sx={{ width: '100%' }}></Box>}
            </Box>
            <Box className='chicken-shit-container'>
                <img src={chickenShit} />
            </Box>
            </Stack>
            </Box>
        </>
    )   
}

function Profile(props) {
    let { username } = useParams();
    const [items, setItems] = useState([]);
    const [profile, setProfile] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [doneLoading, setDoneLoading] = useState(false);



    const StyledLink = styled(NavLink)({
        color: '#101010',
        textDecoration: 'none',
        fontFamily: 'Tiny Font',
        fontSize: '35px',
    });

    const fetchMemes = async() => {
        const url = `${process.env.REACT_APP_MEME_API_URL}/profile/${username}?page=${page}&limit=${MEMES_PER_PAGE}`;
        const resp = await fetch(url);
        const jsonResp = await resp.json();
       
        if (jsonResp.length < MEMES_PER_PAGE) {
            setDoneLoading(true);
        }

        if (jsonResp.length === 0) {
            const url = `${process.env.REACT_APP_MEME_API_URL}/blank/profile/${username}`;
            const pResp = await fetch(url);
            const profileResp = await pResp.json();
            setProfile(profileResp);
        } else if (page === 1) {
            setProfile(jsonResp[0]);
        }

        setPage(page + 1);

        const memes = jsonResp.map(meme => {
            return <MemeImage key={meme.id} memeImg={meme.tweetImgUrl} memeUrl={meme.url} />
        });
        console.log(jsonResp);

        setItems(() => {
            return [...items, ...memes];
        });
    }

    useEffect(() => {
        fetchMemes();

        window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (
            Math.ceil(window.innerHeight + document.documentElement.scrollTop) <=
              document.documentElement.offsetHeight ||
            isFetching
          ) {
              return;
          }
        
        setIsFetching(true);
    };

    useEffect(() => {
        if (!isFetching || doneLoading) return;
        fetchMoreMemes();
      }, [isFetching]);

      const fetchMoreMemes = () => {
        fetchMemes();
        setIsFetching(false);
      };

      
    return (
        <div className="profile-div">
            <Container align="center" className="coc-profile-vector">
                <Box display="flex" justifyContent="center" mt={{ xs: 14, lg: 12 }}>
                <Typography className='header3'>PROFILE</Typography>
                    </Box>
                {/* <div className="crumbs-container">
                    <Breadcrumbs separator="|" mt={3} mb={1}>
                        <StyledLink to="/leaderboard">LEADERBOARD</StyledLink>
                        <StyledLink to={`/profile/${profile.username}`}>PROFILE</StyledLink>
                    </Breadcrumbs>
                </div> */}

                <ProfileBox items={items} profile={profile} />

                <Box mt={{xs: 8, sm: 16, md: 16, lg: 18 }} sx={{ position: 'absolute', right: 0, whiteSpace: 'nowrap'}}>
                    <img src={firstMemesBanner} height={44} />
                    <img className="memes-banner" src={memesBanner} height={44} />
                    <img src={memesBanner} height={44}  />
                </Box>

                <Box mt={{xs: 20, sm: 28, md: 28, lg: 30 }}>
                    <Masonry columns={{ xs: 1, sm: 2, md: 2,lg:3 }}>
                        {items}
                    </Masonry>
                </Box>
            </Container>
        </div>
    )
}

export default Profile;