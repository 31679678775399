import React, { useState,useEffect,useRef } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import intoTheLaboog from '../assets/into-the-laboog-2.png';
import intoTheLaboogMobile from '../assets/into-the-laboog-mobile.png';
import arrowDown from '../assets/arrow-down.png';
import eyeLoader from "../assets/eye-loader.png";
import closeButton from '../assets/close-button.png';
import sgwigly1 from '../assets/sgwigly-1.png';
import sgwigly2 from '../assets/sgwigly-2.png';
import sgwigly3 from '../assets/sgwigly-3.png';
import downloadMe from '../assets/download-me.png';
import cantDownloadMe from '../assets/cant-download-me.png';
import ScrollContainer from 'react-indiana-drag-scroll'
import frog1 from '../assets/frog1.png';
import frog2 from '../assets/frog2.png';
import laboogInfo1 from '../assets/laboog-info-1.png';
import laboogInfo2 from '../assets/laboog-info-2.png';
import laboogInfo3 from '../assets/laboog-info-3.png';
import laboogInfo4 from '../assets/laboog-info-4.png';
import dragIcon from '../assets/drag-icon.png';
import { NavLink } from "react-router-dom"
// import JSZip from "jszip"
// import { saveAs } from 'file-saver';
import sgwigly4 from '../assets/sgwigly-4.png';
import {ReactComponent as Boogers} from '../assets/booger-characters.svg';
import { useHorizontalScroll } from "../components/useSideScroll";

function Characters() {
    const [characters,setChracaters] = useState([])
    const [charactersNames,setChracatersNames] = useState(["cool kid","gilbert","ogz","deg","fesh","squarl","burr","bep","quarl","mando","beard","ched","shremp","big leg "])
    const [charactersSelect,setChracatersSelect] = useState([1,2,3])
    const [selectedCharacter,setSelectedCharacter] = useState(1)
    const [loadingbar,setLoadingBar] = useState(true)
  
    const [open,setOpen]=useState(false)
    const [showModal,setShowModal] = useState(false)
    const handleClose = () =>{setOpen(false)}
    const handleCloseWTF = () =>{setShowModal(false)}
  
  
useEffect(()=>{
    const array14 = []
    for(var i=1;i<15;i++){
        array14.push(i)
    }
    setChracaters(array14)
    setTimeout(()=>{
        setLoadingBar(false)
    },4400)
},[])


useEffect(() => {
  const keyDownHandler = event => {
    console.log('User pressed: ', event.key);

    if (event.key === 'Escape') {
      event.preventDefault();
      // 👇️ your logic here
      handleClose();
    }
  };

  document.addEventListener('keydown', keyDownHandler);
  // 👇️ clean up event listener
  return () => {
    document.removeEventListener('keydown', keyDownHandler);
  };
}, []);

const setOpenModal =(value)=>{
    setSelectedCharacter(value)
    setOpen(true)}
    const download = e => {
        fetch(process.env.PUBLIC_URL +`/select/PFP 0${selectedCharacter<10?"0":""}${selectedCharacter}.zip`, {
          method: "GET",
          headers: {}
        })
          .then(response => {
            response.arrayBuffer().then(function(buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;

              link.setAttribute("download", `PFP 0${selectedCharacter<10?"0":""}${selectedCharacter}.zip`);
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch(err => {
            console.log(err);
          });
      };
      const scrollRef = useHorizontalScroll();
      // const scrollRef = useRef(null);
      // const onWheel = e => {
      //   e.preventDefault();
      //   const container = scrollRef.current;
      //   const containerScrollPosition = scrollRef.current.scrollLeft;
    
      //   container.scrollTo({
      //     top: 0,
      //     left: containerScrollPosition + e.deltaY
      //   });
      // };
      
    return ( 
        <div className='character-container' align="center">
            <Box className={"into-laboog-header"} sx={{ position: 'relative', top: '160px'}}>
                <img src={intoTheLaboog} className="into-the-laboog-2" />
                <img src={intoTheLaboogMobile} className="into-the-laboog-mobile" />
                <button className='wtf-button' onClick={()=>setShowModal(true)}>WTF IS THIS???</button>
            </Box> 
             <Box className={"character-box"}>
            
            {/* <Box sx={{ position: 'fixed', bottom: '-170px', width: '88%', overflowX: 'scroll',  }}> */}
                {/* <img className="laboog-characters" src={laboogCharacters} width="2408"  /> */}
                {/* <Boogers className="booger-character" onClick={() => alert('hey')} /> */}
                {/* <div vertical={false} ref={scrollRef} style={{ overflow: "auto" }}> */}
                <ScrollContainer style={{ whiteSpace: "nowrap" }}   className="scroll-container booger-characters-container">
                {/* <div className='booger-characters-container'> */}
                {characters.map((data)=>{
                    return<>
                    <div className={`booger-character-div booger-character-div-${data}`} onClick={()=>{data==13? console.log(""):setOpenModal(data)}}>
                        <div className='booger-character-select-div'>
                        <div className={`booger-character-select booger-character-select-${data}`} ><img src={process.env.PUBLIC_URL +`/download-me/${data}.png`} className='' />
                        </div>
                    <img className={`booger-character booger-character-${data}`} src={process.env.PUBLIC_URL +`/characters/${data}.png`}/>
                    </div>
                    </div>
                    </>
                })}
                <div className={"dragIcon-div"}>
                <img src={dragIcon} className={"dragIcon"}/>
                </div>
                {/* </div> */}
                </ScrollContainer>
                {/* </div> */}
            </Box> 
            <Modal
        open={showModal}
        onClose={handleCloseWTF}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{backGround:" rgba(0, 0, 0, 0.8)"}}
      >
       <Box className="wtf-laboog-modal">
        <div className='wtf-laboog-modal-container'>
       <h5 className='wtf-laboog-modal-title'>LABOOG IS HERE?</h5>
       <div className='wtf-modal-div-container'>
        <div className='wtf-modal-div'>
          <img src={frog1} className='wtf-laboog-img-1'/>
          <div className='wtf-modal-div-text-1'>
          BOOGLA BLA <span className='wtf-green-text'>CAME TO THE</span>  CET-WORLD!
          <span className='wtf-green-text'> THROUGH A PORTAL LOOKING FOR HIS</span>  GLABAGOOS<span className='wtf-green-text'> (YES THAT HORNY FROGY!!) </span>
          <div className='wtf-green-black-div-text desktop'>BUT HE ENDED UP OPENING A WORLD OF CHAOS!</div>
          <div className='wtf-green-black-div-text mobile'>BUT HE ENDED UP</div> <div className='wtf-green-black-div-text mobile'>OPENING A WORLD OF</div> <div className='wtf-green-black-div-text mobile'>CHAOS!</div>
        </div>
        </div>
        <div className='wtf-modal-div-alt'>
        <div className='wtf-modal-div-text-2'>
        glabagoos vomitted its glaba!!! <br/>
      opening up portals to another world. <span className='wtf-blue-text'>The world of LABOOG!!</span>
      </div>
      <img src={frog2} className='wtf-laboog-img-1'/>
        </div>
        </div>
        <h5 className='wtf-laboog-modal-title'>HOW CAN I BE A PART OF LABOOG??</h5>
        <div className='wtf-green-text'>
        THE ROAD TO LABOOG IS FILLED WITH SURPRISES THAT GETS OPENED UP AS WE PROCEED. 
        THERE IS JUST ONE TRUTH IN LABOOG AND THAT IS THE <span className='wtf-green-black-div-text'>TRUTH OF MEMESSSSSSSS.</span> so LETS BEGIN!!
        </div>
        <div className='wtf-modal-info-div'>
                <img src={laboogInfo1} className='wtf-modal-info-img'/>
                <div className='wtf-modal-div-alt'>
                  <div>
               <span className='wtf-step-title'><span className='wtf-step-title-a'>STEP 1</span> - download character</span> <br/>
          <div className='wtf-modal-info-content'>cLICK ON ANY CHARACTER AND <span className='wtf-blue-text'>DOWNLOAD</span> ITS BOOGER HEAD. YES THESE ASSHOLES ARE CALLED BOOGERSS!!</div>
                </div>
                </div>
                </div>
                <div className='wtf-modal-info-div'>
                <img src={laboogInfo2} className='wtf-modal-info-img'/>
                <div className='wtf-modal-div-alt'>
                  <div>
               <span className='wtf-step-title'><span className='wtf-step-title-a'>STEP 2</span> - create meme</span> <br/>
          <div className='wtf-modal-info-content'>PLAY AROUND WITH THEIR BEAUTIFUL ASS FACESS AND CREATE SOME CONTENT. IT CAN BE ANYTHING FROM <span className='wtf-green-black-div-text'>MEMES, BACKGROUND STORIES, TRAITS ANYTHING THAT ADDS TO LABOOG</span>!!!!!!!</div>
                </div>
                </div>
                </div>
                <div className='wtf-modal-info-div'>
                <img src={laboogInfo3} className='wtf-modal-info-img'/>
                <div className='wtf-modal-div-alt'>
                  <div>
               <span className='wtf-step-title'><span className='wtf-step-title-a'>STEP 3</span> - post on twitter</span>
               <br/>
          <div className='wtf-modal-info-content'>POST THE <span className='wtf-blue-text'>MEMES ON TWITTER</span> WITH <span className='wtf-green-black-div-text-alt'>#laboog</span><br/>
          <br/>
          LET CT ASK THE QUESTION {"‘"}WTF IS LABOOG??</div>
                </div>
                </div>
                </div>
                <div className='wtf-modal-info-div'>
                <img src={laboogInfo4} className='wtf-modal-info-img'/>
                <div className='wtf-modal-div-alt'>
                  <div>
               <span className='wtf-step-title'><span className='wtf-step-title-a'>STEP 4</span> - submit link on</span> <NavLink to="/feeds" className="wtf-link" >MEME FEED</NavLink><br/>
          <div className='wtf-modal-info-content'>SUBMIT THAT TWITTER POST’S LINK IN THE FEED AND WAIT FOR THE BOOGERS TO ACCEPT YOU AS ONE OF THEIR OWN!!</div>
                </div>
                </div>
                </div>
                <h5 className='wtf-laboog-modal-title wtf-laboog-modal-title-alt'>LEADERBOARD</h5>
                <div className='wtf-green-text'>
                THE MORE MEMES YOU POST... THE CLOSER YOU GET TO LABOOG AND THE ADVENTURES THAT LIE IN BETWEEN!!<br/><br/> IF THERE IS A LEADERBOARD...<br/> THEN THERE ARE WINNERS - IF THERE ARE WINNERS
                 <span className='wtf-green-black-div-text'>THEN THERE ARE REWARDS!!</span>
        </div>
        </div>
       </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{backGround:" rgba(0, 0, 0, 0.8)"}}
      >
        <>
        <img src={closeButton} onClick={()=>handleClose()} className="modal-close-btn"/>
        <Box className="character-modal-div">
        <div className='character-modal-header'>Download {charactersNames[selectedCharacter-1]}!!</div>
        <div className='character-select-div'>
        {charactersSelect.map((data)=>{
                    return<>
        <img className={`booger-chracter-selector`} src={process.env.PUBLIC_URL +`/select/PFP 0${selectedCharacter<10?"0":""}${selectedCharacter} ${data}.png`}/>
        </>})}
        
        </div>
        <button className='character-modal-btn' onClick={()=>download()}>Download All Assets <img src={arrowDown}/></button>
        </Box>
        </>
      </Modal>
     {loadingbar? <div className='loadingPage'>
     <img src={sgwigly1} className='sgwigly sgwigly-1'/>
     <img src={sgwigly2} className='sgwigly sgwigly-2'/>
           <img src={eyeLoader} className='loading-animation'/>
           <img src={sgwigly2} className='sgwigly sgwigly-3'/>
     <img src={sgwigly3} className='sgwigly sgwigly-4'/>
      </div>:<></>}
        </div>        
    )
}

export default Characters;